import React from 'react';
import { NodeHeader } from './components/NodeHeader';
import { NodeContentWrapper, NodeWrapper } from './styles';
import { Working } from '../../../components/UI/Working';
import { Body5, Container } from '../../../libs/nvstr-common-ui.es';
import styled from 'styled-components';
import ExecutionFlowSourceHandle from './components/handles/ExecutionFlowSourceHandle';
import ExecutionFlowTargetHandle from './components/handles/ExecutionFlowTargetHandle';
import InputSourceTargetHandle from './components/handles/InputSourceTargetHandle';
import { NODE_HANDLE_IDS } from './index';
import { NodeDebugger } from './components/NodeDebugger';

const ContentWrapper = styled(NodeContentWrapper)`
  width: 600px;
  min-height: 220px;

  padding-bottom: 122px;

  textarea {
    min-height: 60px;
    height: auto;
  }
`;

function formatLargeNumber(num) {
  const units = [
    { value: 1e9, suffix: 'B' },
    { value: 1e6, suffix: 'M' },
    { value: 1e3, suffix: 'K' },
  ];
  for (const unit of units) {
    if (num >= unit.value) {
      return (num / unit.value).toFixed(1).replace(/\.0$/, '') + unit.suffix;
    }
  }
  return num.toString();
}

function formatAnswer(num) {
  if (num < 1) {
    return num.toFixed(2);
  }
  if (num > 1000) {
    return formatLargeNumber(num);
  }
  return num.toFixed(1);
}

function convertToString(arr) {
  let str = '';
  arr.forEach((elem) => {
    const { value, operator } = elem;
    if (value) {
      str += value;
    }
    if (operator) {
      str += ' ' + operator + ' ';
    }
  });
  return str;
}

const Content = ({ id, data, value, onTextChange }) => {
  const { answer, answerFormula, error, isWorking, isComplete } = data;
  if (error) {
    return (
      <ContentWrapper>
        <Container bottom={8}>
          <Body5>Something went wrong.</Body5>
        </Container>
        <Body5>{error}</Body5>
      </ContentWrapper>
    );
  }
  if (isWorking) {
    return (
      <ContentWrapper>
        <Container>
          <Body5>Question Asked: {value}</Body5>
        </Container>
        <Working />
      </ContentWrapper>
    );
  }
  if (answer) {
    return (
      <ContentWrapper>
        <Container>
          <Body5>Question Asked: {value}</Body5>
        </Container>
        <Container top={8}>
          <Container bottom={8}>
            <Body5>Formula: {convertToString(answerFormula)}</Body5>
          </Container>
          <Body5>Answer: {formatAnswer(answer)}</Body5>
        </Container>
      </ContentWrapper>
    );
  }
  return (
    <ContentWrapper>
      <textarea placeholder="Enter the formula to calculate" value={value} onChange={onTextChange} />
    </ContentWrapper>
  );
};

export const FormulaNode = (props) => {
  const { data, id, isConnectable } = props;

  // const defaultQ = 'LTM ROE';
  const defaultQ = '';
  const [value, setValue] = React.useState(defaultQ);

  React.useEffect(() => {
    data.actions.updateData({ question: value });
  }, [value]);

  const onTextChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <NodeWrapper data-id={id}>
      <ExecutionFlowTargetHandle {...props} isConnectable={isConnectable} />
      <ExecutionFlowSourceHandle {...props} isConnectable={isConnectable} />

      <NodeHeader id={id} name={'Formula'} actions={data.actions} />
      <Content id={id} data={data} value={value} onTextChange={onTextChange} />
      <InputSourceTargetHandle
        {...props}
        handleActionId={NODE_HANDLE_IDS.DocumentList}
        isConnectable={isConnectable}
        index={2}
      />
      <InputSourceTargetHandle
        {...props}
        handleActionId={NODE_HANDLE_IDS.CompanyList}
        isConnectable={isConnectable}
        index={1}
      />
      <InputSourceTargetHandle
        {...props}
        handleActionId={NODE_HANDLE_IDS.AdvancedSettings}
        isConnectable={isConnectable}
        index={0}
      />
      <NodeDebugger id={id} />
    </NodeWrapper>
  );
};
