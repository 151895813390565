import { sendApiRequest } from '../../services/api';
import { logNetRequest } from '../../utils/usefulFuncs';
import { clientDocsActions } from '../../constants/actionTypes';
import { useSelector } from 'react-redux';

export async function fetchClientDocs(dispatch) {
  const URL = `v1/genai_custom_presets`;
  const { status, data, error } = await sendApiRequest('get', URL);
  logNetRequest(URL, status, data);
  if (status === 200) {
    try {
      const { ceo_comments, peer_analysis, custom_matrix } = data;
      dispatch({
        type: clientDocsActions.update,
        payload: {
          ceoCommentDocs: ceo_comments,
          peerAnalysisDocColumns: peer_analysis,
          customDefinedDocs: custom_matrix,
        },
      });
    } catch (e) {
      console.log('caught error in client getter', e);
    }
    return true;
  } else {
    // setError('Something went wrong, please try again.');
    return false;
  }
}

export const useClientData = () => {
  const state = useSelector((state) => state.clientData);
  return {
    ceoCommentDocs: state?.ceoCommentDocs,
    peerAnalysisDocs: state?.peerAnalysisDocColumns,
    customDefinedDocs: state?.customDefinedDocs,
  };
};
