import React from 'react';
import styled from 'styled-components';
import { useColorTheme } from '../../hooks';
import { Body1, Body5, Container, SVG_LOADING_ICON_TYPES, SvgLoadingIcon } from '../../libs/nvstr-common-ui.es';
import { convertHexToRGBA } from '../../libs/nvstr-utils.es';
import { TransparentFlatButton } from '../../components/buttons';
import { DocumentIcon } from '../../assets/icons/svgs/DocumentIcon';
import { extractDocTypes } from '../../hooks/features/useDocs';
import { AI_TOOLS, alpha } from '../../constants';
import { docListSort, extractDomain, findFirstLetterIndices, isUndefinedOrNull } from '../../utils/usefulFuncs';
import { smoothScrollToAnchor } from '../../utils/application';
import { useCurrentUserToolsAvailable } from '../../hooks/user/useCurrentUser';
import { SkeletonButton } from '../../components/buttons/SkeletonButton';
import { ROUTES } from '../../constants/routes';
import { useNavigate } from 'react-router';

const Wrapper = styled.div``;
const SelectableDocumentsRowWrapper = styled.div`
  display: grid;
  grid-template-columns: max-content;
  width: max-content;
  height: 80px;
`;
const ColumnsLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr 30px; /* Adjust sizes as needed */
`;
const SelectableDocumentWrapper = styled.div`
  &:last-child {
    //margin-bottom: 0px;
  }

  margin-bottom: 8px;
  margin-right: 16px;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  padding: 12px 16px;
  background: ${({ theme }) => convertHexToRGBA(theme.themeColors.lowContrastBorder, 1)};
  border: 1px solid ${({ theme }) => theme.themeColors.lowContrastBorder};
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.14s;
  flex: 1;

  &:hover {
    background: ${({ theme }) => convertHexToRGBA(theme.themeColors.primaryCtaButton, 0.05)};
    border: 1px solid ${({ theme }) => convertHexToRGBA(theme.themeColors.primaryCtaButton, 0.2)};

    color: ${({ theme }) => theme.themeColors.primaryCtaButton} !important;
  }

  &.selected {
    border: 1px solid ${({ theme }) => theme.themeColors.primaryCtaButton};
    background: ${({ theme }) => convertHexToRGBA(theme.themeColors.primaryCtaButton, 0.2)};

    color: ${({ theme }) => theme.themeColors.buttonText} !important;
  }

  svg {
    height: 18px;
    width: 18px;
    margin-right: 8px;
    margin-left: -4px;

    fill: ${({ theme }) => theme.themeColors.text};
  }
`;
const TickerWrapper = styled.div`
  padding: 4px 8px;
  border: 1px solid ${({ theme }) => theme.themeColors.text};
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.14s;

  &:hover {
    background: ${({ theme }) => convertHexToRGBA(theme.themeColors.primaryCtaButton, 0.1)};
    border: 1px solid ${({ theme }) => convertHexToRGBA(theme.themeColors.primaryCtaButton, 1)};

    * {
      color: ${({ theme }) => theme.themeColors.primaryCtaButton};
    }
  }

  &.selected {
    border: 1px solid ${({ theme }) => theme.themeColors.primaryCtaButton};
    background: ${({ theme }) => theme.themeColors.primaryCtaButton};

    * {
      color: ${({ theme }) => theme.themeColors.buttonText};
    }
  }
`;
const DocumentTypesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 8px;
`;
const SelectionListActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 8px;

  button {
    padding-left: 8px !important;
  }
`;
const RoladexWrapper = styled.div`
  position: sticky;
  position: -webkit-sticky;
  top: 100px;
  right: 0;
  height: 90vh;
`;
const AlphaButtonWrapper = styled.div`
  text-transform: uppercase;
  font-size: 12px;
  line-height: 14px;
  padding: 0 5px 2px 5px;
  width: 20px;
  text-align: center;

  cursor: pointer;
  color: ${({ theme }) => theme.themeColors.text};
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }
`;
const AlphaButton = ({ value }) => {
  const hc = () => {
    smoothScrollToAnchor('#' + value);
  };
  return <AlphaButtonWrapper onClick={hc}>{value}</AlphaButtonWrapper>;
};

function isDocSelected(d, selectedDocs) {
  if (selectedDocs === null) {
    return false;
  }

  let isSelected = false;
  selectedDocs.forEach((selectedDoc) => {
    if (selectedDoc.id === d.id) {
      isSelected = true;
    }
  });
  return isSelected;
}

const DocTypeToggle = ({ value, isSelected, onClick }) => {
  const displayValue = value === 'unknown' || isUndefinedOrNull(value) ? 'Other Materials' : value;
  return (
    <TickerWrapper onClick={() => onClick(value)} className={isSelected ? 'selected' : ''}>
      <Body5>{displayValue}</Body5>
    </TickerWrapper>
  );
};

const SelectableDocument = ({
  data: doc,
  onSelect,
  onRemoveSelect,
  isSelected,
  hasJumpToAnchor,
  isWebsite,
  callWithEntireDoc,
}) => {
  const { filename, added_by_user_id: userId } = doc;
  let className = '';
  if (isSelected) {
    className += 'selected';
  }
  if (userId) {
    className += ' user-uploaded';
  }

  const domain = extractDomain(filename.split(' ')[0]);

  return (
    <div>
      {hasJumpToAnchor && <a id={'#' + domain[0]} />}
      <SelectableDocumentWrapper
        className={className}
        onClick={isSelected ? () => onRemoveSelect(callWithEntireDoc ? doc : filename) : () => onSelect(doc)}
      >
        <DocumentIcon />
        <Body5>{isWebsite ? domain : filename}</Body5>
      </SelectableDocumentWrapper>
    </div>
  );
};

const SelectableDocumentsList = ({
  activeTopLevelFilter,
  activeDocType,
  docs,
  selectedDocs,
  onSelect,
  onRemoveSelect,
  callWithEntireDoc,
}) => {
  const [sortedDocs, setSortedDocs] = React.useState(null);

  React.useEffect(() => {
    if (docs) {
      const list = docs.filter((d) => d.display_category === activeDocType);
      const listWithTopLevelFilter = applyTopLevelFilter(activeTopLevelFilter, list);
      const docType = listWithTopLevelFilter[0] ? listWithTopLevelFilter[0].display_category : null;
      const s = docListSort(listWithTopLevelFilter, docType);
      setSortedDocs(s);
    }
  }, [docs, activeDocType, activeTopLevelFilter]);

  if (!sortedDocs) return null;

  const isWebsites = sortedDocs.every((d) => d.doc_type === 'Website');
  if (isWebsites && sortedDocs.length > 20) {
    const letterIndices = findFirstLetterIndices(sortedDocs.map((d) => extractDomain(d.filename.split(' ')[0])));
    return (
      <ColumnsLayout>
        <SelectableDocumentsRowWrapper>
          {sortedDocs.map((d, i) => (
            <SelectableDocument
              isWebsite
              hasJumpToAnchor={letterIndices.includes(i)}
              key={d?.id}
              data={d}
              onSelect={onSelect}
              onRemoveSelect={onRemoveSelect}
              isSelected={isDocSelected(d, selectedDocs)}
              callWithEntireDoc={callWithEntireDoc}
            />
          ))}
        </SelectableDocumentsRowWrapper>
        <RoladexWrapper>
          {letterIndices.map((value, i) => (value > -1 ? <AlphaButton key={i} value={alpha[i]} /> : null))}
        </RoladexWrapper>
      </ColumnsLayout>
    );
  }
  return (
    <SelectableDocumentsRowWrapper>
      {sortedDocs.map((d) => (
        <SelectableDocument
          key={d.id}
          data={d}
          onSelect={onSelect}
          onRemoveSelect={onRemoveSelect}
          isSelected={isDocSelected(d, selectedDocs)}
          callWithEntireDoc={callWithEntireDoc}
        />
      ))}
    </SelectableDocumentsRowWrapper>
  );
};

function applyTopLevelFilter(topLevelFilter, docs) {
  if (!docs) return docs;
  if (!topLevelFilter) return docs;

  if (topLevelFilter === 'Uploaded') {
    return docs.filter((d) => !!d.added_by_user_id);
  }

  return docs.filter((d) => d.security_symbol === topLevelFilter);
}

export const DocumentSelection = ({
  docs,
  tickers,
  singleDocMode,
  selectedDocs,
  noDocsMessage,
  onSelect,
  onRemoveSelect,
  callWithEntireDoc,
}) => {
  const colorTheme = useColorTheme();
  const navigate = useNavigate();
  const toolsAvailable = useCurrentUserToolsAvailable();
  const isUploadsEnabled = toolsAvailable.includes(AI_TOOLS.UPLOAD_SOURCE_DOC);

  const [topLevelFilters, setTopLevelFilters] = React.useState([]);
  const [docTypes, setDocTypes] = React.useState([]);

  const [activeTopLevelFilter, setActiveTopLevelFilter] = React.useState(tickers?.length > 0 ? tickers[0] : null);
  const [activeDocumentType, setActiveDocumentType] = React.useState(null);

  React.useEffect(() => {
    let nextTopLevelFilters;
    if (tickers) {
      nextTopLevelFilters = [...tickers, 'Uploaded'];
    } else {
      nextTopLevelFilters = ['Uploaded'];
    }
    if (nextTopLevelFilters.length > 1) {
      setTopLevelFilters(nextTopLevelFilters);
    }

    const filtered = applyTopLevelFilter(activeTopLevelFilter, docs);
    const docTypes = extractDocTypes(filtered);
    setDocTypes(docTypes);
  }, [docs, activeDocumentType, activeTopLevelFilter, tickers]);

  // to set active doctype, top-level-filter
  React.useEffect(() => {
    let nextTopLevelFilters;
    if (tickers) {
      nextTopLevelFilters = [...tickers, 'Uploaded'];
    } else {
      nextTopLevelFilters = ['Uploaded'];
    }
    if (activeTopLevelFilter === null || !nextTopLevelFilters.includes(activeTopLevelFilter)) {
      if (nextTopLevelFilters.length > 1) {
        setActiveTopLevelFilter(nextTopLevelFilters[0]);
      }
    }

    const filtered = applyTopLevelFilter(activeTopLevelFilter, docs);
    const docTypes = extractDocTypes(filtered);
    if (activeDocumentType === null || !docTypes.includes(activeDocumentType)) {
      docTypes.length > 0 && setActiveDocumentType(docTypes[0]);
    }
  }, [docs, activeDocumentType, activeTopLevelFilter, tickers]);

  const handleUploadClick = () => {
    navigate(ROUTES.docManagement);
  };
  const handleDocTypeClick = (v) => {
    setActiveDocumentType(v);
  };
  const handleTopLevelFilterClick = (v) => {
    setActiveTopLevelFilter(v);
  };

  const handleSelectAllRecentDocs = () => {
    if (activeDocumentType !== null) {
      const tabbedDocs = applyTopLevelFilter(activeTopLevelFilter, docs);
      const recent = tabbedDocs.filter((d) => d).filter((d) => d.is_recent);
      if (recent.length > 0) {
        onSelect(recent);
      }
    }
  };

  const handleSelectAll = () => {
    if (activeDocumentType !== null) {
      const tabbedDocs = docs.filter((d) => d.display_category === activeDocumentType);
      if (tabbedDocs.length > 0) {
        onSelect(tabbedDocs);
      }
    }
  };

  const handleUnselectAll = () => {
    const tabbedDocs = docs.filter((d) => d.display_category === activeDocumentType);
    const docNames = tabbedDocs?.map((d) => d.filename);
    if (docNames.length > 0) {
      onRemoveSelect(tabbedDocs);
    }
  };

  if (!docs) {
    return (
      <Wrapper>
        <Container centerAll top={72} bottom={96}>
          <SvgLoadingIcon type={SVG_LOADING_ICON_TYPES.bars} color={colorTheme.text} />
          <Container left={16}>
            <Body1>Loading...</Body1>
          </Container>
        </Container>
      </Wrapper>
    );
  }

  if (docs.length === 0) {
    return (
      <Wrapper>
        <Container top={36} bottom={0}>
          <Container left={16}>
            <Body5>{noDocsMessage || 'Select a ticker to choose see a list of available documents.'}</Body5>
            {isUploadsEnabled && (
              <Container top={16}>
                {noDocsMessage ? (
                  <Body5>Have a document to upload? Upload it now.</Body5>
                ) : (
                  <Body5>You can also upload your own documents that will show here.</Body5>
                )}
                <Container top={24}>
                  <SkeletonButton onClick={handleUploadClick}>Upload Documents</SkeletonButton>
                </Container>
              </Container>
            )}
          </Container>
        </Container>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      {topLevelFilters.length > 1 ? (
        <Container bottom={16}>
          <DocumentTypesWrapper>
            {topLevelFilters.map((filter) => (
              <DocTypeToggle
                key={filter}
                value={filter}
                onClick={handleTopLevelFilterClick}
                isSelected={activeTopLevelFilter === filter}
              />
            ))}
          </DocumentTypesWrapper>
        </Container>
      ) : null}
      <DocumentTypesWrapper>
        {docTypes.map((docType) => (
          <DocTypeToggle
            key={docType}
            value={docType}
            onClick={handleDocTypeClick}
            isSelected={activeDocumentType === docType}
          />
        ))}
      </DocumentTypesWrapper>
      <Container top={24}>
        {!singleDocMode && (
          <Container bottom={8}>
            <SelectionListActionsWrapper>
              <TransparentFlatButton onClick={handleUnselectAll}>Unselect All</TransparentFlatButton>
              <TransparentFlatButton onClick={handleSelectAll}>Select All</TransparentFlatButton>
              <TransparentFlatButton onClick={handleSelectAllRecentDocs}>Select All Recent Docs</TransparentFlatButton>
            </SelectionListActionsWrapper>
          </Container>
        )}
        <SelectableDocumentsList
          docs={docs}
          activeTopLevelFilter={activeTopLevelFilter}
          activeDocType={activeDocumentType}
          selectedDocs={selectedDocs}
          onSelect={onSelect}
          onRemoveSelect={onRemoveSelect}
          callWithEntireDoc={callWithEntireDoc}
        />
      </Container>
    </Wrapper>
  );
};
