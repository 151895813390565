export const ROUTES = {
  splash: '/',
  home: '/',
  faq: '/faq',

  login: '/login',
  signup: '/signup',
  resetPassword: '/reset_password',
  forgotPassword: '/forgot_password',

  inReview: '/in-review',

  themeBuilder: '/theme-builder',

  fedDigitalAnalyst: '/fed-digital-analyst',
  fedPublicReleaseAnalyst: '/the-fed-release-analyst',
  fedPublicSentimentAnalyst: '/the-fed-sentiment-analyst',
  fedLatestDocs: '/fed-latest-docs',

  digitalAnalyst: '/digital-analyst',
  researchDigitalAnalyst: '/research-digital-analyst',

  companySnapshot: '/company-snapshot',
  customSnapshot: '/custom-snapshot',

  docManagement: '/document-management',
  uploadSource: '/document-management',

  factor: '/factor',
  factorVisual: '/factor-visual',

  screener: '/screener',
  researchScreener: '/research-screener',

  growthEquityAnalysis: '/growth-equity-analysis',

  latestUpdates: '/latest-updates',
  latestEarningsUpdates: '/earnings-latest-updates',

  lenderEarningsUpdates: '/lender-earnings-analysis',

  stock: '/stock',
  docViewer: '/view-document',
  docSummary: '/view-summary',

  compliance: '/compliance',
  queryHistory: '/query-history',

  docProcessingToDA: '/await-doc-processing',
  warningIndicators: '/warning-indicators',
  bullBearCases: '/bull-bear-cases',

  website: '/website-digital-analyst',
  webpageIngestion: '/website-summary',

  evaluationTemplate: '/eval-template',

  macroEconomicSignals: '/macro-economic-signals',
  economicImpact: '/economic-impact',

  textSearch: '/text-search',
  accountSettings: '/account-settings',
  watchlist: '/watchlist',

  liquidityTermsTableAnalysis: '/liquidity-terms-analysis',
  ppmTableAnalysis: '/ppm-analysis',
  tableAnalysis: '/table-analysis',
  creditAgreementAnalysis: '/credit-agreement-analysis',

  queryEngine: '/query-engine',
  queryFlow: '/query-flow',

  quickLook: '/quick-look',

  clientMatrix: '/client-matrix-questions',
  clientPeerMatrix: '/peer-analysis-matrix-questions',
  clientQuery: '/client-ask-digital-analyst',
};
