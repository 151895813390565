import React from 'react';
import { Handle, Position } from '@xyflow/react';
import { useColorTheme } from '../../../../../hooks';
import { generateCustomInputNodeHandle } from '../../index';
import styled from 'styled-components';
import { Body5 } from '../../../../../libs/nvstr-common-ui.es';

const DEFAULT_HANDLE_STYLE = {
  top: 'auto',
  bottom: '13px',
  right: '3px',
};

const Wrapper = styled.div``;
const PositionName = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;

  padding-right: 8px;

  right: 2px;
  bottom: 6px;

  height: 34px;
`;
const NameWrapper = styled.div`
  padding-bottom: 4px;

  * {
    color: ${({ theme, ColorName }) => theme.themeColors[ColorName]};
  }
`;

export default React.memo(({ data, isConnectable, handleActionId }) => {
  const colorTheme = useColorTheme();
  const { name, colorThemeName } = generateCustomInputNodeHandle(handleActionId);
  return (
    <Wrapper>
      <PositionName>
        <NameWrapper ColorName={colorThemeName}>
          <Body5 bold>{name}</Body5>
        </NameWrapper>
      </PositionName>

      <Handle
        type="source"
        id={handleActionId}
        position={Position.Right}
        style={{
          ...DEFAULT_HANDLE_STYLE,
          bottom: '6px',
          background: colorTheme[colorThemeName],
        }}
        isConnectable={isConnectable}
      />
    </Wrapper>
  );
});
