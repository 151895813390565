import React from 'react';
import { NodeHeader } from './components/NodeHeader';
import { NodeContentWrapper, NodeWrapper } from './styles';
import { Body5, Body7, CheckCircle, Container } from '../../../libs/nvstr-common-ui.es';
import { FlatButton, TransparentFlatButton } from '../../../components/buttons';
import { Working } from '../../../components/UI/Working';
import styled from 'styled-components';
import ExecutionFlowSourceHandle from './components/handles/ExecutionFlowSourceHandle';

const ContentWrapper = styled(NodeContentWrapper)`
  min-width: 300px;
  min-height: 120px;

  button {
    width: 160px;
  }
`;
const CheckWrapper = styled.div`
  svg {
    height: 16px;
    width: 16px;

    path {
      fill: ${({ theme }) => theme.themeColors.text};
    }
  }
`;

const Content = ({ data, onRun, onClearAnswers }) => {
  const { isRunning, isComplete, didError, message } = data;

  if (didError) {
    return (
      <Container>
        <Body5>An error occurred during output. Please try again.</Body5>
        <Container top={24}>
          <FlatButton onClick={onRun}>Run</FlatButton>
        </Container>
      </Container>
    );
  }

  if (isRunning) {
    return (
      <Container vertical={8}>
        <Working noVerticalPadding />
      </Container>
    );
  }

  if (isComplete) {
    return (
      <Container fullWidth>
        <Container top={8} left={2} row verticallyCenter>
          <CheckWrapper>
            <CheckCircle />
          </CheckWrapper>
          <Container left={8} bottom={3}>
            <Body5 bold>Completed</Body5>
          </Container>
        </Container>
        <Container top={30} centerAll>
          <FlatButton onClick={onRun}>Re-Run</FlatButton>
        </Container>
        <Container top={4} centerAll>
          <TransparentFlatButton onClick={onClearAnswers}>Clear Answers</TransparentFlatButton>
        </Container>
      </Container>
    );
  }

  return (
    <Container>
      <Container bottom={24}>
        <Body5 bold>{message}</Body5>
      </Container>
      <FlatButton onClick={onRun}>Run</FlatButton>
    </Container>
  );
};

export default React.memo((props) => {
  const { data, id, isConnectable } = props;

  const onRun = () => {
    data.actions.onRunOutput();
  };

  const onClearAnswers = () => {
    data.actions.onClearAnswers();
  };

  return (
    <NodeWrapper data-id={id}>
      <NodeHeader id={id} name={'Start'} Left={8} />
      <ContentWrapper>
        <Container bottom={24}>
          <Body7>Drag down to start and select your first node.</Body7>
        </Container>
        <Content data={data} onRun={onRun} onClearAnswers={onClearAnswers} />
      </ContentWrapper>
      <ExecutionFlowSourceHandle {...props} isConnectable={isConnectable} />
    </NodeWrapper>
  );
});
