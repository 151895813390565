import React from 'react';
import styled from 'styled-components';
import { Header } from '../components/navigation';
import { Body } from '../components/layout/Body';
import { useNavigate } from 'react-router';
import { ROUTES } from '../constants/routes';
import { scrollToTop } from '../utils/usefulFuncs';
import QuickLookTool from '../containers/DigitalAnalyst/QuickLookTool';
import { applicationActionTypes } from '../constants/actionTypes';
import { useDispatch } from 'react-redux';
import { CURRENT_DEFAULT_MODEL } from '../constants';

const PageWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const defaultAdvancedSettings = {
  model: CURRENT_DEFAULT_MODEL,
  enableSmoothing: false,
  maxContextLength: '80000',
  maxContextChunks: '600',
  maxAnswerLength: '1000',
};

function QuickLook() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onGoBack = () => navigate(ROUTES.home);

  React.useEffect(() => {
    scrollToTop();
  }, []);

  React.useEffect(() => {
    dispatch({
      type: applicationActionTypes.setAdvancedSettings,
      payload: defaultAdvancedSettings,
    });
  }, []);

  return (
    <PageWrapper>
      <Header />
      <Body withFooter>
        <QuickLookTool onGoBack={onGoBack} />
      </Body>
    </PageWrapper>
  );
}

export default QuickLook;
