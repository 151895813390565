import { AI_TOOLS, CURRENT_DEFAULT_MODEL, docTypes } from '../constants';
import { getResultId } from '../hooks/features/useAskDigitalAnalyst';
import { logger } from '../utils/usefulFuncs';
import { createCompanyListPrompt, NODE_TYPES } from '../containers/flow/nodes';
import { sendApiRequest } from '../services/api';
import { NodeData, NodeDataUpdater } from './NodeUtils';
import { createTimeInstance, formatLocalizedDateTime } from '../libs/nvstr-utils.es';
import {
  addOptionalSettings,
  buildNodeActionLookup,
  completeIngestionStatuses,
  executeQuestionForCompany,
  parseAnswerFormula,
} from '../utils/flowEngine';
import { FormulaProcessor } from './FormulaProcessor';
import { extractJsonString, isJsonCodeBlock } from '../utils';

export class NodeExecutor {
  constructor(node, state, mutableState) {
    this.node = node;
    this.state = state;
    this.mutableState = mutableState;

    this.muteableNode = this.mutableState.nodes.find((n) => n.id === node.id);

    this.nodeActionsLookup = buildNodeActionLookup(state);
    this.nodeActions = this.nodeActionsLookup[node.id];

    this.nextNodes = [];
    this.error = null;
  }

  execute = async () => {
    logger('execute node: ' + this.node.type, { node: this.muteableNode, state: this.state });

    switch (this.node.type) {
      case NODE_TYPES.FormulaNode: {
        const { nodes, edges } = this.mutableState;
        const nodeData = new NodeData(this.node, nodes, edges);
        const {
          selectedDocs,

          advancedSettings,
          selectedCompanies,
          companies,

          extraContext,
          nextNodes,
        } = nodeData.data;

        const model = CURRENT_DEFAULT_MODEL;

        const userQuestion = this.muteableNode.data.question;
        const question = `Create the formula needed that meets this description "${userQuestion}". Only answer with the formula, do not include extra text. Answer in JSON as an array with each value or operator in its own object, if its a value, have a key "value: <value>", if its an operator have a key "operator: <operator>"`;
        const _companies = selectedCompanies || companies;

        this.nodeActions.updateData({ isWorking: true });

        const params = {
          selectedDocs,
          screen_mode: false,

          tool: AI_TOOLS.QUERY_ENGINE,

          extraContext,
          model,
          enableFollowUpQuestions: false,
        };
        if (_companies.length > 0) {
          params.tickers = _companies.filter((c) => c.symbol).map((c) => c.symbol);
        }
        addOptionalSettings(params, advancedSettings);

        const { id: resultId, error } = await getResultId(question, params, {});

        const nodeUpdater = new NodeDataUpdater(this.muteableNode, this.nodeActions);

        if (error) {
          nodeUpdater.handleError(error);
          return false;
        }

        let answerFormulaJSON = null;
        const handleFormulaCreateComplete = (answer) => {
          if (!isJsonCodeBlock(answer)) {
            return '';
          }

          const string = extractJsonString(answer);
          answerFormulaJSON = string;
        };
        await nodeUpdater.streamCompleteHandler(resultId, handleFormulaCreateComplete);

        const answerFormula = parseAnswerFormula(answerFormulaJSON);
        this.nodeActions.updateData({ answerFormula });

        const formulaProcessor = new FormulaProcessor(answerFormula, this.muteableNode, this.nodeActions, nodes, edges);
        const questionParams = formulaProcessor.generateQuestionParams(userQuestion);
        await formulaProcessor.generateFormulaValues(questionParams);
        const answer = await formulaProcessor.processAnswer();
        this.nodeActions.updateData({ answer, isWorking: false });

        this.nextNodes = nodeData.getConditionalNextNodes(this.muteableNode, this.nodeActions);
        return true;
      }
      case NODE_TYPES.IfElseNode: {
        const { nodes, edges } = this.mutableState;
        const nodeData = new NodeData(this.node, nodes, edges);
        const {
          selectedDocs,

          advancedSettings,
          selectedCompanies,
          companies,

          extraContext,
        } = nodeData.data;
        const _companies = selectedCompanies || companies;

        const model = CURRENT_DEFAULT_MODEL;

        const questionPrefix =
          'Answer the following question with only one word "YES" or "NO" based on the context. Question: ';
        const userQuestion = this.muteableNode.data.question;
        const question = questionPrefix + userQuestion;

        const params = {
          selectedDocs,
          screen_mode: false,

          tool: AI_TOOLS.QUERY_ENGINE,

          extraContext,
          model,
          enableFollowUpQuestions: false,
        };
        if (_companies.length > 0) {
          params.tickers = _companies.filter((c) => c.symbol).map((c) => c.symbol);
        }
        addOptionalSettings(params, advancedSettings);

        const { id: resultId, error } = await getResultId(question, params, {});
        const nodeUpdater = new NodeDataUpdater(this.muteableNode, this.nodeActions);

        if (error) {
          nodeUpdater.handleError(error);
          return false;
        }

        await nodeUpdater.handleStreamAnswer(resultId);

        this.nextNodes = nodeData.getConditionalNextNodes(this.muteableNode, this.nodeActions);
        return true;
      }
      case NODE_TYPES.ForEachCompanyNode: {
        const { nodes, edges } = this.mutableState;
        const node = new NodeData(this.muteableNode, nodes, edges);
        const {
          children,
          actionNode,
          parentNodes,
          advancedSettings,
          companies,

          nodeContext,
          extraContext,

          nextNodes,
        } = node.data;

        const forEachNodeActions = this.nodeActionsLookup[this.node.id];

        logger('node params', {
          companies,
          actionNode,
          actionType: actionNode?.type,
          extraContext,
          advancedSettings,
          children,
          nodeContext,
          parentNodes,
        });

        const actionNodeActions = this.nodeActionsLookup[actionNode.id];

        let action;
        if (actionNode.type === NODE_TYPES.ForEachCompanyQuestionNode) {
          action = (company) => {
            const question = actionNode.data.question;
            const data = { question, company, extraContext };
            const extraParams = {};
            addOptionalSettings(extraParams, advancedSettings);
            return executeQuestionForCompany(data, extraParams, actionNode, actionNodeActions);
          };
        }
        if (actionNode.type === NODE_TYPES.ForEachIngestWebsiteNode) {
          action = async (company) => {
            const question = actionNode.data.question;
            const data = { question, company, extraContext };
            const extraParams = {};
            addOptionalSettings(extraParams, advancedSettings);
            return executeQuestionForCompany(data, extraParams, actionNode, actionNodeActions);
          };
        }
        const requests = companies.map((company) => {
          return action(company);
        });

        if (companies.length === 0) {
          const error = 'no companies were found in iterator';
          logger(error, companies);
          this.muteableNode.data.error = error;
          actionNode.data.error = error;
          this.nodeActions.updateData({ error });
          actionNodeActions.updateData({ error });
          this.error = true;
          return false;
        }
        await Promise.all(requests);

        if (actionNode.type === NODE_TYPES.ForEachIngestWebsiteNode) {
          let ingestAction;
          const answers = actionNode.data.answers;
          const companyWebsites = Object.values(answers).filter((url) => url !== 'N/A');

          ingestAction = async (website) => {
            const enableJS = true;
            const numOfPages = 20;
            const version = 2;
            const form = { url: website, run_js: enableJS, max_pages: numOfPages, crawl_version: version };
            const URL = `v1/genai_process_web_page`;
            const { status, data, error } = await sendApiRequest('post', URL, form);
            return { status, url: website };
          };

          const requests = companyWebsites.map((url) => ingestAction(url));
          actionNodeActions.updateData({ isIngestingWebsites: true });
          const responses = await Promise.all(requests);
          if (responses.every((r) => r.status === 200)) {
            const { error, documents } = await completeIngestionStatuses(companyWebsites, this.nodeActions);
            const selectedDocs = documents;
            logger('completeIngestion', error, documents);
            this.muteableNode.data.selectedDocs = selectedDocs;
            forEachNodeActions.updateData({ selectedDocs });
            actionNodeActions.updateData({ isIngestingWebsitesComplete: true, isComplete: true });
            if (error) {
              actionNodeActions.updateData({ wasIngestionError: true });
            }
          } else {
            // fires if a request fails to start ingestion
            actionNodeActions.updateData({ wasIngestionError: true });
          }
        }

        this.nextNodes = nextNodes;
        return true;
      }
      case NODE_TYPES.GenerateCompanyListNode: {
        const { nodes, edges } = this.mutableState;
        const node = new NodeData(this.node, nodes, edges);
        const {
          selectedDocs,
          advancedSettings,
          extraContext,

          nextNodes,
        } = node.data;

        const model = CURRENT_DEFAULT_MODEL;

        const question = createCompanyListPrompt(this.muteableNode.data.question);
        const isScreenMode = selectedDocs.length === 0;
        const params = {
          selectedDocs,
          screen_mode: isScreenMode,

          tool: AI_TOOLS.QUERY_ENGINE,

          extraContext,

          model,
          enableContext: true,
        };
        addOptionalSettings(params, advancedSettings);

        if (isScreenMode) {
          const includeDocTypes = [
            docTypes['40F'],
            docTypes['10K'],
            docTypes['10Q'],
            docTypes['20F'],
            docTypes['8K'],
            docTypes['6K'],
            docTypes.EARNINGS,
          ];
          const screenModeDefaults = {
            doc_types: includeDocTypes,
            start_date: formatLocalizedDateTime(
              'api',
              createTimeInstance(createTimeInstance().subtract(18, 'months').toDate())
            ),
            end_date: formatLocalizedDateTime('api', createTimeInstance(createTimeInstance().toDate())),
          };
          addOptionalSettings(params, screenModeDefaults);
        }

        // const tempResultId = nanoid(6);
        const { id: resultId, error } = await getResultId(question, params, {});

        const nodeUpdater = new NodeDataUpdater(this.muteableNode, this.nodeActions);

        if (error) {
          nodeUpdater.handleError(error);
          return false;
        }

        await nodeUpdater.handleStreamAnswer(resultId);

        this.nextNodes = nextNodes;
        return true;
      }
      case NODE_TYPES.PromptNode: {
        const { nodes, edges } = this.mutableState;
        const node = new NodeData(this.node, nodes, edges);
        const {
          selectedDocs,
          advancedSettings,
          selectedCompanies,
          companies,

          extraContext,
          nextNodes,
        } = node.data;
        const _companies = selectedCompanies || companies;

        const model = CURRENT_DEFAULT_MODEL;

        const question = this.muteableNode.data.question;
        const params = {
          selectedDocs,
          screen_mode: selectedDocs.length === 0 && _companies.length === 0,

          tool: AI_TOOLS.QUERY_ENGINE,

          extraContext,
          model,
          enableFollowUpQuestions: false,
        };
        if (_companies.length > 0) {
          params.tickers = _companies.filter((c) => c.symbol).map((c) => c.symbol);
        }
        addOptionalSettings(params, advancedSettings);

        const { id: resultId, error } = await getResultId(question, params, {});

        const nodeUpdater = new NodeDataUpdater(this.muteableNode, this.nodeActions);

        if (error) {
          nodeUpdater.handleError(error);
          return false;
        }

        await nodeUpdater.handleStreamAnswer(resultId);
        this.nextNodes = nextNodes;
        return true;
      }

      default: {
        logger('missing node type handler for execution', this.node.type);
        this.error = true;
        return false;
      }
    }

    return false;
  };
}
