import React from 'react';
import { DocumentSelection } from '../DocumentSelection';
import { Loading } from '../../../components/UI/Loading';
import { useClientData } from '../../../hooks/features/useClientData';

export const PresetDocumentSelection = ({
  presetParams,
  selectedDocs,
  handleAddDocument,
  handleRemoveDocument,
  onDismiss,
}) => {
  const [concatDocs, setConcatDocs] = React.useState([]);

  const clientData = useClientData();

  React.useEffect(() => {
    if (clientData.customDefinedDocs) {
      setConcatDocs(clientData.customDefinedDocs);
    }
  }, [clientData]);

  if (!clientData.customDefinedDocs) {
    return <Loading />;
  }

  return (
    <DocumentSelection
      tickers={presetParams.tickers}
      docs={concatDocs}
      selectedDocs={selectedDocs}
      onSelect={handleAddDocument}
      onRemoveSelect={handleRemoveDocument}
      onClose={onDismiss}
      callWithEntireDoc
    />
  );
};
