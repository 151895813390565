import React from 'react';
import styled from 'styled-components';
import { AppFooter } from '../../containers/application/AppFooter';
import { convertHexToRGBA } from '../../libs/nvstr-utils.es';
import { usePartnerTheme } from '../../hooks/user/useCurrentUser';

export const ContentWrapper = styled.div`
  min-height: calc(100vh - 220px);
`;
export const BodyWrapper = styled.div`
  max-width: 1640px;
  margin: 0 auto;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    width: 100%;
  }
`;
export const XLPage = styled.div`
  background: ${({ theme }) => theme.themeColors.componentNoOpacity};
  border-top: 1px solid ${({ theme }) => theme.themeColors.lowContrastBorder};
  margin: 16px;
`;
export const XLBodyWrapper = styled.div`
  position: relative;
  margin: 0 auto;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    width: 100%;
  }
`;

export const FooterSeparator = styled.div`
  border-top: 1px solid ${({ theme }) => convertHexToRGBA(theme.themeColors.text, 0.1)};
  padding-top: 24px;
  margin-top: 24px;

  opacity: 0.7;
`;

export const ExtraWideBody = ({ children, withFooter }) => {
  const partnerTheme = usePartnerTheme();
  const showFooter = withFooter && partnerTheme !== 1;

  return (
    <div>
      <XLBodyWrapper>{children}</XLBodyWrapper>
      {showFooter && (
        <FooterSeparator>
          <BodyWrapper>
            <AppFooter />
          </BodyWrapper>
        </FooterSeparator>
      )}
    </div>
  );
};

export const Body = ({ children, withFooter }) => {
  const partnerTheme = usePartnerTheme();
  const showFooter = withFooter && partnerTheme !== 1;

  return (
    <div>
      <BodyWrapper>
        <ContentWrapper>{children}</ContentWrapper>
      </BodyWrapper>
      {showFooter && (
        <FooterSeparator>
          <BodyWrapper>
            <AppFooter />
          </BodyWrapper>
        </FooterSeparator>
      )}
    </div>
  );
};
