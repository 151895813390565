export const GRID_MODES = {
  fed: 'Table Analysis - Fed',
  credit: 'Table Analysis - Credit',
  earnings: 'Table Analysis - Earnings',
  ppm: 'Table Analysis - PPM',
  client: 'Table Analysis - Custom',
  clientPeerAnalysis: 'Table Analysis - Peer Analysis',
  misc: 'Table Analysis - Generic',
  liquidityTerms: 'Table Analysis - Liquidity Terms',
};

function buildCreditPrompt(id, display, prompt) {
  return {
    id: 'credit-prompt-' + id,
    display_label: display,
    prompt: prompt,
    is_default: true,
    is_diff_mode: false,
    is_my_prompt: false,
    is_shared: false,
    block_editting: true,
  };
}

function buildFedPrompt(id, display, prompt, isDiffMode) {
  return {
    id: 'fed-prompt-' + id,
    display_label: display,
    prompt: prompt,
    is_default: true,
    is_diff_mode: !!isDiffMode,
    is_my_prompt: false,
    is_shared: false,
    block_editting: true,
  };
}

export const DEFAULT_FED_MODE_PROMPTS = [
  buildFedPrompt(
    1,
    'Quotes on conditions needed by the Fed to start cutting interest rates',
    'What did the Fed speaker say on the conditions needed by the Fed to start cutting interest rates? Include exact quotes.',
    false
  ),
  buildFedPrompt(
    2,
    'Quotes on evolution of conditions needed for the Fed to start cutting rates',
    "How did the Fed speaker's thinking evolve on the conditions needed for the Fed to start cutting interest rates between this transcript and the previous one?  Include exact quotes.",
    true
  ),
  buildFedPrompt(
    3,
    'Summary of conditions needed by the Fed to start cutting interest rates',
    'Outline the conditions needed by the Fed to start cutting interest rates.',
    false
  ),
  buildFedPrompt(
    4,
    'Summary of evolution of conditions needed for the Fed to start cutting rates',
    'Outline how the conditions needed for the Fed to start cutting interest rates evolved between this transcript and the previous one.',
    true
  ),
  buildFedPrompt(
    5,
    'Quotes on inflation targets',
    'What did the Fed speaker say about inflation targets?  Include exact quotes.',
    false
  ),
  buildFedPrompt(
    6,
    'Quotes on evolution of inflation targets',
    "How did the Fed speaker's thinking evolve on inflation targets between this transcript and the previous one? Include exact quotes.",
    true
  ),
  buildFedPrompt(
    7,
    'Quotes on measures of inflation',
    'What measures of inflation do the Fed speaker consider most important?  Include exact quotes.',
    false
  ),
  buildFedPrompt(
    8,
    'Quotes on evolution of measures of inflation',
    'How did the measures of inflation considered by the Fed speaker to be most important change between this transcript and the previous one? Include exact quotes.',
    true
  ),
];

export const DEFAULT_GENERIC_MODE_PROMPTS = [];

export const DEFAULT_CREDIT_MODE_PROMPTS = [
  buildCreditPrompt(1, 'Entity', 'Answer with only the entity that is the borrower / obligor of this bond or loan'),
  buildCreditPrompt(
    2,
    'Tranches',
    'Show a table with all the specific tranches (e.g., term loans, revolvers, bonds, or letters of credit) in this credit facility or bond issuance, and include columns with respective total principal size or current aggregate revolving commitment, and the respective interest rate'
  ),
  buildCreditPrompt(3, 'Guarantors', 'List any guarantors including parent companies or other entities'),
  buildCreditPrompt(
    4,
    'Collateral',
    'Show a table with the collateral (e.g, specific assets, inventory, equity in subsidiaries, etc.) and what is the priority (e.g., 1st Lien, 2nd Lien, unsecured, etc.)'
  ),
  buildCreditPrompt(
    5,
    'Covenants',
    'What are the restrictive covenants (e.g., interest coverage, restricted payments, negative pledges, debt incurrence, maintenance, etc)? Include details on relevant metrics (e.g., debt to EBITDA thresholds) and the relevant section. Summarize in a table'
  ),
];
