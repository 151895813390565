import { clientDocsActions } from '../constants/actionTypes';

const defaultState = {
  ceoDocs: null,
  peerAnalysisDocs: null,
  customDefinedDocs: null,
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case clientDocsActions.update: {
      return {
        ...state,
        ...action.payload,
      };
    }

    default:
      return state;
  }
}
