import React, { useState } from 'react';
import { AI_TOOLS } from '../../constants';
import { handle400Statuses, sendApiRequest } from '../../services/api';
import { asyncForEach, extractIntegerAfterHash, logger, logNetRequest } from '../../utils/usefulFuncs';
import { streamResult } from './useStreamingResult';
import { parseFullContextFilename } from '../../utils/result';
import ContextParser from '../../classes/ContextParser';

let id = 1;

const getResult = async (resultId) => {
  let result = null;
  const onError = (error) => {
    console.log('error occurred', error);
  };
  const onComplete = (throwaway, response) => {
    result = response;
  };
  const handlers = {
    onError,
    onComplete,
  };
  const options = { enableQualityCheck: true };
  await streamResult(resultId, handlers, options);
  return result;
};

function addIdToMarkdownLinks(markdown) {
  const regex = /\[([^\]]+)\]\(([^)]+)\)/g;
  let updatedMarkdown = markdown;

  updatedMarkdown = updatedMarkdown.replace(regex, (match, text, url) => {
    return `[${text}](${url}?id=${id++})`;
  });

  return updatedMarkdown;
}

const extractCitationsList = (citations, context, index) => {
  const contextParser = new ContextParser(context, index);
  contextParser.process();
  return citations.map((citation) => {
    const { chunkIndex, header, filename, citationId } = citation;
    const text = contextParser.getContextItem(citation);
    return {
      citationId,
      chunkIndex,
      filename,
      items: [{ ...citation, text }],
    };
  });
};

const parseCitationResponse = (response, index) => {
  const { context, answer } = response;
  const answerWithIds = addIdToMarkdownLinks(answer);
  const citations = extractMarkdownLinks(answerWithIds);
  console.log({ citations });
  const citationsList = extractCitationsList(citations, context, index);

  return {
    citationAnswer: answerWithIds,
    citationsList,
  };
};

export function extractIdFromUrl(url) {
  try {
    const urlObj = new URL(url);
    return urlObj.searchParams.get('id');
  } catch (e) {
    return null;
  }
}

function extractMarkdownLinks(markdown) {
  const regex = /\[([^\]]+)\]\(([^)]+)\)/g;
  const matches = [];
  let match;

  while ((match = regex.exec(markdown)) !== null) {
    let header = match[1];
    if (header.includes('@')) {
      header = header.split('@@@ ')[1];
    }
    const url = match[2];

    if (header === '' || !header) {
      logger('something was wrong with the header', { header, match });
    }

    const filename = parseFullContextFilename(header);
    const chunkIndex = extractIntegerAfterHash(header);
    const citationId = extractIdFromUrl(url);
    const data = {
      citationId,
      filenameAndChunk: header,
      url,
      header,
      filename,
      chunkIndex,
    };
    matches.push(data);
  }

  return matches;
}

export const useOrderedCitations = () => {
  const [isGettingCitations, setIsGettingCitations] = useState(false);
  const [statusCount, setStatusCount] = useState(0);
  const [citations, setCitations] = useState([]);
  const [citationAnswers, setCitationAnswers] = useState({});

  React.useEffect(() => {
    return () => {
      id = 1;
    };
  }, []);

  const getCitation = async (resultId, index) => {
    console.log('getting citation for: ' + resultId);

    const form = {
      record_id: resultId,
      user_mode: AI_TOOLS.QUICK_LOOK,
    };

    setIsGettingCitations(true);
    setStatusCount((prevState) => prevState + 1);
    const URL = `v1/genai_qa_citation/stream`;
    const { status, data, error } = await sendApiRequest('post', URL, form);
    setIsGettingCitations(false);
    logNetRequest(status, data, error);
    handle400Statuses(status);
    if (status === 200) {
      const { id } = data;
      const result = await getResult(id);
      return parseCitationResponse(result, index);
    } else {
      return {
        error: true,
      };
    }
  };

  const getCitations = async (resultIds) => {
    let _citationsAnswers = {};
    let allCitationsList = [];

    await asyncForEach(resultIds, async (id, index) => {
      const result = await getCitation(id);
      // console.log('parsed result', result);
      const { citationAnswer, citationsList } = result;
      _citationsAnswers[index] = citationAnswer;
      citationsList.forEach((citationAnswer) => {
        allCitationsList.push(citationAnswer);
      });
    });

    // console.log('complete', _citationsAnswers, allCitationsList);
    setCitationAnswers(_citationsAnswers);
    setCitations(allCitationsList);
  };

  return {
    getCitations,
    isGettingCitations,
    statusCount,
    citations,
    citationAnswers,
  };
};
