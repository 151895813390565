import React from 'react';
import { handle400Statuses, sendApiRequest } from '../../services/api';
import { addUrlParam, unify } from '../../utils';
import { handleTickerGrouping } from '../../utils/mutations';
import { useCurrentUser } from '../user/useCurrentUser';
import { isEarningsFilename, logger, logNetRequest } from '../../utils/usefulFuncs';
import { filterDocDuplicates } from '../../reducers/docs';
import { earningsDocTypes } from '../../constants';
import { GRID_MODES } from '../../containers/gridMode';

const initialState = {
  isLoading: true,
};

const ACTIONS = {
  update: 'u',
  startLoading: 'sl',
  error: 'err',
};

let filterId = 0;

function reducer(state, action) {
  switch (action.type) {
    case ACTIONS.startLoading: {
      const { filters } = action.payload;
      return {
        ...state,
        currentFilters: filters,
        docs: [],
        isLoading: true,
      };
    }
    case ACTIONS.update: {
      const { data, filters } = action.payload;

      return {
        ...state,
        isLoading: false,
        docs: data,
      };
    }
    case ACTIONS.error: {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    }

    default:
      throw new Error('missing case: useDocs');
  }
}

const createParamsFromFilters = (filters) => {
  let URLParams = '';
  const {
    ticker,
    tickers,
    custom,
    isResearchMode,
    companiesOnly,
    isWebMode,
    isQueryMode,
    isFedMode,
    isUserOnlyMode,
    docMode,
  } = filters || {};

  if (custom) {
    URLParams = addUrlParam(URLParams, 'topic=' + custom);
  }
  if (isQueryMode && (!tickers || tickers.length === 0)) {
    URLParams = addUrlParam(URLParams, 'user_uploaded=true');
  }
  if (companiesOnly) {
    URLParams = addUrlParam(URLParams, 'topic=company');
  }
  if (docMode === GRID_MODES.ppm) {
    URLParams = addUrlParam(URLParams, 'user_uploaded=true');
    URLParams = addUrlParam(URLParams, 'doc_types=PPM,Side Letter');
  }
  if (docMode === GRID_MODES.credit) {
    URLParams = addUrlParam(URLParams, 'user_uploaded=true');
    URLParams = addUrlParam(URLParams, 'doc_types=Loan Agreement,Indenture,Bond Prospectus,Prospectus');
  }
  if (docMode === GRID_MODES.earnings) {
    URLParams = addUrlParam(URLParams, 'user_uploaded=true');
    URLParams = addUrlParam(URLParams, 'doc_types=Earnings Call Transcript,Conference Transcript');
  }
  if (isUserOnlyMode) {
    URLParams = addUrlParam(URLParams, 'user_uploaded=true');
  }
  if (typeof ticker === 'string' && ticker.length > 0) {
    URLParams = addUrlParam(URLParams, `ticker=${ticker}`);
  }
  if (tickers?.length > 0) {
    URLParams = addUrlParam(URLParams, `tickers=${tickers.join(',')}`);
  }
  if (isResearchMode) {
    URLParams = addUrlParam(URLParams, `user_mode=research_query`);
  }
  if (docMode === GRID_MODES.fed || isFedMode) {
    URLParams = addUrlParam(URLParams, `user_mode=fed_query`);
    URLParams = addUrlParam(URLParams, `topic=macro`);
    URLParams = addUrlParam(URLParams, 'user_uploaded=true');
  }
  if (isWebMode) {
    URLParams = addUrlParam(URLParams, `topic=website`);
  }
  return URLParams;
};

export const filterFedUserDocs = (docs) => {
  return docs.filter((d) => d.security_symbol === null && d.doc_type === 'unknown');
};
export const extractDocTypes = (docs) => {
  if (!docs) return [];

  const longDocTypes = [];
  const shortDocTypes = [];
  const endOfList = [];
  docs.forEach((d) => {
    const { display_category, doc_type } = d;
    const lookAtType = display_category;
    if (lookAtType === 'unknown') {
      !endOfList.includes('unknown') && endOfList.push(lookAtType);
    } else {
      if (lookAtType?.length > 7) {
        !longDocTypes.includes(lookAtType) && longDocTypes.push(lookAtType);
      } else {
        !shortDocTypes.includes(lookAtType) && shortDocTypes.push(lookAtType);
      }
    }
  });
  return [...shortDocTypes.sort(), ...longDocTypes.sort(), ...endOfList];
};

const filterDocs = (docs, filters) => {
  if (!filters) return docs;

  if (filters.earnings) {
    return docs.filter((d) => {
      return earningsDocTypes.includes(d.doc_type) || isEarningsFilename(d.filename);
    });
  }

  if (filters.userUploaded) {
    return docs.filter((d) => {
      return d.added_by_user_id > 0;
    });
  }

  return docs;
};

const fetchFilteredDocs = async (filters) => {
  if (!filters) throw new Error('Missing filters in fetchFilteredDocs');

  let URL = `v1/genai_tickers_filenames` + createParamsFromFilters(filters);
  const { status, data } = await sendApiRequest('get', URL);
  logNetRequest(URL, status, data);
  handle400Statuses(status);
  return { status, data };
};

function setErrored(dispatch) {
  dispatch({
    type: ACTIONS.error,
    payload: 'An unexpected error occurred. Please refresh and try again.',
  });
}

export const useFilteredDocs = (filters, onAfterDocsReceived) => {
  const currentUser = useCurrentUser();
  const [state, dispatch] = React.useReducer(reducer, initialState, () => initialState);

  React.useEffect(() => {
    let currentStatus = { isCancelled: false };

    const onFiltersChange = async () => {
      dispatch({
        type: ACTIONS.startLoading,
        payload: { filters },
      });

      const { status, data } = await fetchFilteredDocs(filters);
      if (!currentStatus.isCancelled) {
        if (status === 200 && !currentStatus.isCancelled) {
          const mutatedData = handleTickerGrouping(data, filters.ticker, currentUser.id);
          const docs = unify(Object.values(mutatedData));
          const payloadData = filterDocDuplicates(docs);
          dispatch({
            type: ACTIONS.update,
            payload: {
              filters,
              data: filterDocs(payloadData, filters),
            },
          });
          return payloadData;
        } else {
          setErrored(dispatch);
          return null;
        }
      }

      const receivedDocs = await fetchFilteredDocs(filters);
      onAfterDocsReceived && onAfterDocsReceived(receivedDocs);
    };
    onFiltersChange(currentStatus);

    return () => {
      currentStatus.isCancelled = true;
    };
  }, [filters]);

  return state;
};

export const useDocs = (filters, onAfterDocReceived) => {
  const currentUserId = useCurrentUser().id;
  const [state, dispatch] = React.useReducer(reducer, initialState, () => initialState);

  const fetchDocs = React.useCallback(
    async (paramFilters, onAfterDocReceived) => {
      let filters = { ...paramFilters };
      if (!filters.id) {
        filters = {
          ...paramFilters,
          id: filterId++,
        };
      }
      dispatch({
        type: ACTIONS.startLoading,
        payload: { filters },
      });

      let URL = `v1/genai_tickers_filenames` + createParamsFromFilters(filters);
      const { status, data } = await sendApiRequest('get', URL);
      logNetRequest(URL, status, data);
      handle400Statuses(status);

      // HACK::: user uploaded fed docs enabled again
      let extraDocs = [];
      if (filters.isFedMode || filters.docMode === GRID_MODES.fed || filters.isQueryMode) {
        let URL = `v1/genai_tickers_filenames?user_uploaded=true`;
        const { status, data } = await sendApiRequest('get', URL);
        logNetRequest(URL, status, data);
        if (status === 200) {
          try {
            const userDocs = Object.values(data)[0] || [];
            extraDocs = filters.isFedMode ? [...filterFedUserDocs(userDocs)] : userDocs;
          } catch (e) {
            logger('No user uploaded docs');
          }
        }
      }

      if (status === 200) {
        const mutatedData = handleTickerGrouping(data, filters.ticker, currentUserId);
        const docs = unify(Object.values(mutatedData));
        const concatDocs = [...docs, ...extraDocs];
        const payloadData = filterDocDuplicates(concatDocs);
        const filteredPayloadData = filterDocs(payloadData, filters);

        dispatch({
          type: ACTIONS.update,
          payload: {
            filters,
            data: filteredPayloadData,
          },
        });

        onAfterDocReceived && onAfterDocReceived(filteredPayloadData);
        return payloadData;
      } else {
        dispatch({
          type: ACTIONS.error,
          payload: 'An unexpected error occurred. Please refresh and try again.',
        });
        return null;
      }
    },
    [dispatch]
  );

  React.useEffect(() => {
    const onFiltersChange = async () => {
      const filtersIdentified = {
        ...filters,
        id: filterId++,
      };
      fetchDocs(filtersIdentified, onAfterDocReceived);
    };
    onFiltersChange();
  }, [filters]);

  return { ...state, fetchDocs };
};
