import {
  convertUrlToIngestionCompletedDocument,
  fetchUploadStatus,
  isUrlIngestionComplete,
} from '../hooks/features/useUploadStatus';
import { logger } from './usefulFuncs';
import { AI_TOOLS, CURRENT_DEFAULT_MODEL } from '../constants';
import { getResultId } from '../hooks/features/useAskDigitalAnalyst';
import { streamResult } from '../hooks/features/useStreamingResult';

export async function completeIngestionStatuses(websites) {
  let isComplete = false;
  let error = null;

  return new Promise((resolve) => {
    const delay = 10 * 1000;
    const interval = setInterval(async () => {
      const response = await fetchUploadStatus();
      const { groupedData } = response;

      if (groupedData) {
        const { completed, errored } = groupedData;
        const hasCompletedAllWebsites = websites.every((url) => isUrlIngestionComplete(url, completed));
        const wasError = websites.some((url) => isUrlIngestionComplete(url, errored));
        logger('ingestion check', { hasCompletedAllWebsites, wasError, completed, errored });
        if (wasError) {
          error = true;
          clearInterval(interval);
          resolve({
            isComplete,
            error,
          });
        }

        if (hasCompletedAllWebsites) {
          isComplete = true;
          clearInterval(interval);
          resolve({
            isComplete,
            error,
            documents: convertUrlToIngestionCompletedDocument(websites, completed),
          });
        }
      }
    }, delay);
  });
}

export const fetchNonStreamResult = async (data, extraParams, node, nodeActions) => {
  const { company, question, extraContext } = data;
  const model = CURRENT_DEFAULT_MODEL;

  const params = {
    tickers: [company.symbol],
    tool: AI_TOOLS.QUERY_ENGINE,

    extraContext: extraContext || null,
    model,

    enableFollowUpQuestions: false,
    ...extraParams,
  };
  const { id: resultId, error } = await getResultId(question, params, {});
};

export const executeQuestionForCompany = async (data, extraParams, node, nodeActions) => {
  const { company, question, extraContext } = data;
  const model = CURRENT_DEFAULT_MODEL;

  const params = {
    tickers: [company.symbol],
    tool: AI_TOOLS.QUERY_ENGINE,

    extraContext: extraContext || null,
    model,

    enableFollowUpQuestions: false,
    ...extraParams,
  };
  const { id: resultId, error } = await getResultId(question, params, {});

  if (error) {
    node.data.error = error;
    nodeActions.updateData({ error });
    logger('error getting result', node);
    return false;
  }

  const onOpen = () => {
    if (!('answers' in node.data)) {
      node.data.answers = {};
    }
    node.data.answers[company.symbol] = 'Working...';
    nodeActions.updateAnswers({ [company.name + ' ' + company.symbol]: 'Working...' });
  };
  const onMessage = (answer) => {
    node.data.answers[company.symbol] = answer;
    nodeActions.updateAnswers({ [company.name + ' ' + company.symbol]: answer });
  };
  const onRefiningStart = () => {
    node.data.isRefining = true;
    nodeActions.updateData({ isRefining: true });
  };
  const onError = (error) => {
    node.data.error = error;
    nodeActions.updateData({ error });
  };
  const onComplete = ({ answer }) => {
    node.data.answers[company.symbol] = answer;
    nodeActions.updateAnswers({ [company.name + ' ' + company.symbol]: answer });
  };
  const handlers = {
    onOpen,
    onMessage,
    onRefiningStart,
    onError,
    onComplete,
  };
  const options = { enableQualityCheck: true };
  return streamResult(resultId, handlers, options);
};

export function parseAnswerFormula(answer) {
  try {
    return JSON.parse(answer);
  } catch (e) {
    return null;
  }
}

export function addOptionalSettings(formData, settings) {
  const updatedFormData = formData;
  Object.entries(settings).forEach(([key, value]) => {
    if (value !== '') {
      updatedFormData[key] = value;
    }
  });
  return updatedFormData;
}

export function buildNodeActionLookup(state) {
  const lookup = {};
  for (const node of state.nodes) {
    lookup[node.id] = node.data.actions;
  }
  return lookup;
}
