import { evaluate } from 'mathjs';
import { addOptionalSettings } from '../utils/flowEngine';
import { NodeData } from './NodeUtils';
import { getResultId } from '../hooks/features/useAskDigitalAnalyst';
import { AI_TOOLS, CURRENT_DEFAULT_MODEL } from '../constants';
import { logger } from '../utils/usefulFuncs';

function formatAINumberAnswer(numString) {
  if (typeof numString !== 'string') {
    throw new Error('Input must be a string');
  }

  // Remove commas and extra spaces
  let cleanedString = numString.replace(/,/g, '').trim();

  // Handle words like billion, million, thousand
  const multipliers = {
    billion: 1e9,
    million: 1e6,
    thousand: 1e3,
  };

  // Regex to match patterns like "123 million" or "4.5 billion"
  const regex = /([\d\.]+)\s*(billion|million|thousand)/i;
  const match = cleanedString.match(regex);

  if (match) {
    const value = parseFloat(match[1]);
    const multiplier = multipliers[match[2].toLowerCase()];
    return value * multiplier;
  }

  // Remove any remaining non-numeric characters and parse as a float
  cleanedString = cleanedString.replace(/[^\d\.\-]/g, '');
  return parseFloat(cleanedString);
}

function findYearIn2000s(inputString) {
  const yearRegex = /\b(20[0-9]{2})\b/;
  const match = inputString.match(yearRegex);
  return match ? match[1] : null;
}

export class FormulaProcessor {
  constructor(answerFormula, muteableNode, nodeActions, nodes, edges) {
    this.muteableNode = muteableNode;
    this.nodeActions = nodeActions;

    this.nodes = nodes;
    this.edges = edges;

    this.formula = answerFormula;
    this.formulaWithValues = null;
  }

  generateQuestionParams(userQuestion) {
    const year = findYearIn2000s(userQuestion);
    if (year) {
      return {
        year,
      };
    } else return null;
  }

  async generateValue(prompt) {
    const node = new NodeData(this.muteableNode, this.nodes, this.edges);
    const {
      children,
      actionNode,
      parentNodes,
      advancedSettings,

      selectedDocs,
      companies,
      selectedCompanies,

      nodeContext,
      extraContext,

      nextNodes,
    } = node.data;

    const _companies = selectedCompanies || companies;
    const company = _companies[0];
    const question = `Provide only the value that answers the question. If unable to get value answer with "N/A". Question: ${prompt}`;
    const extraParams = {};

    const model = CURRENT_DEFAULT_MODEL;

    const params = {
      screen_mode: false,

      tool: AI_TOOLS.QUERY_ENGINE,

      extraContext,
      model,
      enableFollowUpQuestions: false,
    };
    if (_companies?.length > 0) {
      params.tickers = [company].map((c) => c.symbol);
    }
    if (selectedDocs?.length > 0) {
      params.selectedDocs = selectedDocs;
    }
    addOptionalSettings(params, advancedSettings);

    const options = {
      nonStream: true,
    };
    const { answer } = await getResultId(question, params, options);
    return formatAINumberAnswer(answer);
  }

  async generateFormulaValues(questionParams) {
    const formulaWithValues = [];
    const requests = [];
    const extraValueText = questionParams?.year ? questionParams.year + ' ' : '';
    this.formula.forEach((el, index) => {
      const { value, operator } = el;
      if (value) {
        requests.push([extraValueText + value, index]);
      }
      if (operator) {
        formulaWithValues[index] = operator;
      }
    });
    const asyncRequests = await Promise.all(requests.map(async (el) => [await this.generateValue(el[0]), el[1]]));
    asyncRequests.forEach((request) => {
      formulaWithValues[request[1]] = request[0];
    });
    this.formulaWithValues = formulaWithValues;
    return this.formulaWithValues;
  }

  processAnswer() {
    let formulaString = '';
    formulaString = this.formulaWithValues.join('');
    logger('formulaString', formulaString, this.formulaWithValues);
    const result = evaluate(formulaString);
    return result;
  }
}
