import { extractIntegerAfterHash, findBestFileNameMatch, isUndefinedOrNull } from '../../../utils/usefulFuncs';
import { Body5, Container, SVG_LOADING_ICON_TYPES, SvgLoadingIcon } from '../../../libs/nvstr-common-ui.es';
import { CitationIcon } from '../../../assets/icons/svgs/CitationIcon';
import React from 'react';
import styled from 'styled-components';
import { useColorTheme } from '../../../hooks';
import { extractIdFromUrl } from '../../../hooks/features/useOrderedCitations';

const CitationWrapper = styled.span`
  position: relative;
  display: inline-block;
  cursor: pointer;
  padding-right: ${({ IdValue }) =>
    IdValue?.length === 1 ? '5px' : IdValue?.length === 2 ? '8px' : IdValue?.length === 3 ? '15px' : '0px'};

  svg {
    height: 14px;
    width: 14px;

    path {
      fill: ${({ theme }) => theme.themeColors.text};
    }
  }

  &:hover {
    * {
      opacity: 0.6;
    }
  }
`;
const CitationIdWrapper = styled.div`
  position: absolute;
  top: -6px;
  right: ${({ Value }) => (Value.length === 2 ? '-1px' : Value.length === 3 ? '0px' : '-1px')};
  font-size: 8px;
  font-weight: bold;
  color: ${({ theme }) => theme.themeColors.text};
`;

function altFindHeader(string) {
  const split = string.split('-');
  const possibleHeaderNumber = parseFloat(split[split.length - 1]);
  if (!isNaN(possibleHeaderNumber)) {
    return possibleHeaderNumber;
  } else {
    return null;
  }
}

function matchFilename(string, sourceDocs) {
  return sourceDocs.find((doc) => string.includes(doc.filename));
}

function parseChunkData(string, sourceDocs) {
  let matchedFile = matchFilename(string, sourceDocs);
  let matchedHeader = extractIntegerAfterHash(string);

  if (!matchedFile) matchedFile = findBestFileNameMatch(string, sourceDocs);
  if (!matchedHeader) matchedHeader = altFindHeader(string);

  if (isNaN(matchedHeader)) {
    return {
      doc: null,
      chunkId: null,
    };
  }

  if (sourceDocs.length === 1 && !isNaN(matchedHeader)) {
    return {
      doc: sourceDocs[0],
      chunkId: matchedHeader,
    };
  }

  return {
    doc: matchedFile,
    chunkId: matchedHeader,
  };
}

export function AddingCitations() {
  const colorTheme = useColorTheme();

  return (
    <Container row verticallyCenter bottom={8}>
      <SvgLoadingIcon type={SVG_LOADING_ICON_TYPES.bars} color={colorTheme.text} />
      <Container left={8} bottom={8}>
        <Body5>Adding Citations...</Body5>
      </Container>
    </Container>
  );
}

const CitationId = ({ value }) => {
  return <CitationIdWrapper Value={value}>{value}</CitationIdWrapper>;
};

export const Citation = ({ isShowingCitations, metadata, url, data, onClick, sourceDocs }) => {
  const debug = false;

  const citationId = extractIdFromUrl(url);
  const { doc, chunkId } = parseChunkData(data, sourceDocs);

  const hc = (e) => {
    e.preventDefault();
    onClick(chunkId, doc, citationId);
  };

  if (isUndefinedOrNull(doc)) return null;
  if (!isShowingCitations) return null;
  return (
    <CitationWrapper IdValue={citationId} onClick={hc}>
      {debug ? <Body5>{data}</Body5> : null}
      <CitationIcon />
      {citationId && <CitationId value={citationId} />}
    </CitationWrapper>
  );
};
