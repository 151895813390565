import React from 'react';
import { Body3, Body5, Container, Page } from '../../../libs/nvstr-common-ui.es';
import { useNewCompanySnapshotStreamingResult } from '../../../hooks/features/useNewCompanySnapshotStreamingResult';
import styled from 'styled-components';
import { ResultOnly } from './AnswerLine';
import { logger } from '../../../utils/usefulFuncs';
import { SkeletonButton } from '../../../components/buttons/SkeletonButton';
import { ROUTES } from '../../../constants/routes';
import { extractJsonString, isJsonCodeBlock } from '../../../utils';

const BulletWrapper = styled.div``;
const RowAnswerWrapper = styled.div`
  padding: 2px 0;
  border-bottom: 1px solid ${({ theme }) => theme.themeColors.border};

  display: grid;
  grid-template-columns: 180px 1fr;
`;
const SectionsWrapper = styled.div``;
const SectionWrapper = styled.div`
  padding: 24px 0 0 0;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
`;
const ReportWrapper = styled.div`
  padding: 16px 0 16px 8px;

  ol li {
    padding: 0 0 8px 0;

    ul {
      padding: 16px 0 0 24px;

      li {
        list-style-type: disc;
      }
    }

    margin: 0 0 16px 0;
  }

  ul li {
    margin: 0 0 4px 0;
  }
`;

const CustomAnswerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  p {
    margin: 0;
  }

  * {
    font-size: ${({ IsHeading }) => (IsHeading ? '24px' : '16px')} !important;
  }
`;

const CustomAnswer = ({ label, data, IsHeading, index }) => {
  const answer = data ? data[index] : null;
  if (!answer)
    return (
      <CustomAnswerWrapper IsHeading={IsHeading}>
        <Container verticallyCenter>
          <Body5 bold>{label} </Body5>
        </Container>
      </CustomAnswerWrapper>
    );

  return (
    <CustomAnswerWrapper IsHeading={IsHeading}>
      {label && (
        <Container right={12}>
          <Body5 bold>{label} </Body5>
        </Container>
      )}

      <ResultOnly result={answer} />
    </CustomAnswerWrapper>
  );
};

const RowAnswer = ({ label, data, index }) => {
  const answer = data ? data[index] : null;
  if (!answer)
    return (
      <RowAnswerWrapper>
        <Container verticallyCenter>
          <Body5 bold>{label} </Body5>
        </Container>
        <Container verticallyCenter>
          <Body5>Working...</Body5>
        </Container>
      </RowAnswerWrapper>
    );

  return (
    <RowAnswerWrapper>
      <Container verticallyCenter>
        <Body5 bold>{label} </Body5>
      </Container>
      <Container verticallyCenter>
        <ResultOnly result={answer} />
      </Container>
    </RowAnswerWrapper>
  );
};

const EvalAnswer = ({ bullet, data, index }) => {
  const answer = data ? data[index] : null;
  if (!answer)
    return (
      <BulletWrapper>
        <Body3>Working...</Body3>
      </BulletWrapper>
    );

  return (
    <BulletWrapper>
      <Body3 bold>{bullet} </Body3>
      <ResultOnly result={answer} />
    </BulletWrapper>
  );
};

const TableAnswerWrapper = styled.div`
  padding: 16px 0 16px 0;

  table {
    margin: 0;
    padding: 0;

    * {
      font-size: 14px;
      color: ${({ theme }) => theme.themeColors.text};
    }

    th {
      padding: 4px 8px;
      border-bottom: 1px solid ${({ theme }) => theme.themeColors.border};
    }

    td {
      padding: 4px 8px;
    }
  }
`;
const TH = styled.th`
  * {
    font-weight: ${({ Bold }) => (Bold ? 'bold' : 'normal')};
  }
`;
const TR = styled.tr`
  * {
    font-weight: ${({ Bold }) => (Bold ? 'bold' : 'italic')};
    font-style: ${({ Bold }) => (Bold ? 'normal' : 'italic')};
    font-size: ${({ Bold }) => (Bold ? '14px' : '13px')};
  }

  td {
    border-bottom: ${({ Bold, theme }) => (Bold ? 'none' : '1px solid ' + theme.themeColors.border)};
  }
`;
const RightAlignTD = styled.td`
  text-align: right;
`;

function createCellsFromAnswer(data) {
  try {
    if (!isJsonCodeBlock(data)) {
      return [];
    }

    const string = extractJsonString(data);
    const answer = JSON.parse(string);
    const answers = Object.values(answer);
    return answers.map((a) => a.toString());
  } catch (e) {
    logger('error', data);
    return [];
  }
}

function formatToMillions(input) {
  let number = parseFloat(input);

  if (isNaN(number)) {
    return 'ERR';
  }

  const millions = number / 1000000;
  if (millions < 1) {
    const formatted = millions.toFixed(2);
    return `$${formatted}M`;
  } else {
    const formatted = millions.toFixed(1);
    return `$${formatted}M`;
  }
}

function formatCellNumber(cell) {
  // Round to one decimal, and use the abbreviation M for millions eg $X.XM
  return formatToMillions(cell);
}

function formatCellAnswer(cell, label) {
  if (cell === 'N/A') return cell;
  if (label.includes('%')) {
    return cell.includes('%') ? cell : cell + '%';
  }
  return formatCellNumber(cell);
}

const TableAnswerRow = ({ answerIndex, label, data, columnCount, bold }) => {
  if (!data || !data[answerIndex])
    return (
      <TR Bold={bold}>
        <td>{label}</td>
        <td colSpan={columnCount}>
          <Body5>Working...</Body5>
        </td>
      </TR>
    );

  const answer = data[answerIndex];
  const cells = createCellsFromAnswer(answer);

  return (
    <TR Bold={bold}>
      <td>{label}</td>
      {cells.length === 0 ? (
        <td colSpan={columnCount}>
          <Body5>Working...</Body5>
        </td>
      ) : (
        cells.map((cell, i) => (
          <RightAlignTD index={i}>
            <Body5>{formatCellAnswer(cell, label)}</Body5>
          </RightAlignTD>
        ))
      )}
    </TR>
  );
};

const TableAnswer = ({ data, columns, labels, indexes }) => {
  return (
    <TableAnswerWrapper>
      <table>
        <thead>
          <tr>
            <TH></TH>
            {columns.map((c) => (
              <TH Bold={true} key={c}>
                {c}
              </TH>
            ))}
          </tr>
        </thead>
        <tbody>
          {indexes.map((answerIndex, i) => (
            <TableAnswerRow
              key={i}
              label={labels[i]}
              answerIndex={answerIndex}
              data={data}
              columnCount={columns.length}
              bold={!(i % 2)}
            />
          ))}
        </tbody>
      </table>
    </TableAnswerWrapper>
  );
};

export const GrowthEquityAnalysisResult = ({ resultId, docIds, questionList }) => {
  const [labels] = React.useState(['Revenue', 'YoY Growth %', 'Gross Profit', 'Margin %', 'Net Profit', 'Margin %']);
  const [columns] = React.useState(['FY20A', 'FY21A', 'FY22A', 'FY23A', 'FY24E']);
  const state = useNewCompanySnapshotStreamingResult(resultId, questionList);
  const { answersLookup, error: streamingError } = state;

  const onQueryDocumentsClick = () => {
    const url = ROUTES.digitalAnalyst + `?doc-ids=${docIds.join(',')}`;
    window.open(url, '_blank');
  };

  return (
    <Page width={'1400px'}>
      <Container>
        <ReportWrapper>
          <Container row verticallyCenter spaceBetween>
            <Container>
              <CustomAnswer data={answersLookup} IsHeading index={0} />
            </Container>
            <Container left={8}>
              <SkeletonButton onClick={onQueryDocumentsClick}>Query Documents</SkeletonButton>
            </Container>
          </Container>

          <Container top={12}>
            <CustomAnswer data={answersLookup} label={'WIP Goal: '} index={1} />
          </Container>

          <Container top={24}>
            <SectionsWrapper>
              <SectionWrapper>
                <Container>
                  <Body5 uppercase bold>
                    Overview
                  </Body5>
                  <Container top={16}>
                    <RowAnswer data={answersLookup} label={'Deal Team'} index={2} />
                    <RowAnswer data={answersLookup} label={'Sector / Industry'} index={3} />
                    <RowAnswer data={answersLookup} label={'Location'} index={4} />
                    <RowAnswer data={answersLookup} label={'Financing Type'} index={5} />
                    <RowAnswer data={answersLookup} label={'Financing Size'} index={6} />
                    <RowAnswer data={answersLookup} label={'Lead Source'} index={7} />
                    <RowAnswer data={answersLookup} label={'Banked'} index={8} />
                  </Container>
                </Container>
                <Container>
                  <Body5 uppercase bold>
                    Deal Synopsis
                  </Body5>
                  <Container>
                    <EvalAnswer data={answersLookup} label={''} index={12} />
                    <EvalAnswer data={answersLookup} label={''} index={13} />
                    <EvalAnswer data={answersLookup} label={''} index={14} />
                    <EvalAnswer data={answersLookup} label={''} index={15} />
                  </Container>
                </Container>
              </SectionWrapper>
              <SectionWrapper>
                <Container>
                  <Body5 uppercase bold>
                    Summary Financials
                  </Body5>
                  <Container>
                    <TableAnswer
                      data={answersLookup}
                      labels={labels}
                      columns={columns}
                      indexes={[22, 23, 24, 25, 26, 27]}
                    />
                  </Container>
                </Container>
                <Container>
                  <Body5 uppercase bold>
                    Business Overview
                  </Body5>
                  <Container>
                    <EvalAnswer data={answersLookup} label={''} index={16} />
                    <EvalAnswer data={answersLookup} label={''} index={17} />
                    <EvalAnswer data={answersLookup} label={''} index={18} />
                  </Container>
                </Container>
              </SectionWrapper>
              <SectionWrapper>
                <Container>
                  <Body5 uppercase bold>
                    Overview of Cap Table
                  </Body5>
                  <Container top={24}>
                    <RowAnswer data={answersLookup} label={'Most Recent Round'} index={9} />
                    <RowAnswer data={answersLookup} label={'Equity Raised to Date'} index={10} />
                    <RowAnswer data={answersLookup} label={'Debt Outstanding'} index={11} />
                  </Container>
                </Container>

                <Container>
                  <Body5 uppercase bold>
                    Preliminary View, Key Areas of Diligence, & Next Steps
                  </Body5>
                  <Container>
                    <EvalAnswer data={answersLookup} label={''} index={19} />
                    <EvalAnswer data={answersLookup} label={''} index={20} />
                    <EvalAnswer data={answersLookup} label={''} index={21} />
                  </Container>
                </Container>
              </SectionWrapper>
            </SectionsWrapper>
          </Container>
        </ReportWrapper>
      </Container>
    </Page>
  );
};
