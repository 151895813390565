import React from 'react';
import styled from 'styled-components';
import { Header } from '../components/navigation';
import { Body } from '../components/layout/Body';
import { useNavigate } from 'react-router';
import { ROUTES } from '../constants/routes';
import { scrollToTop } from '../utils/usefulFuncs';
import NewQueryTool from '../containers/DigitalAnalyst/NewQueryTool';
import { Body5, Container, Page, SVG_LOADING_ICON_TYPES, SvgLoadingIcon } from '../libs/nvstr-common-ui.es';
import { useColorTheme } from '../hooks';
import BackArrowButton from '../components/buttons/BackArrowButton';
import { AI_TOOLS } from '../constants';
import { useClientData } from '../hooks/features/useClientData';

const PageWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const tool = AI_TOOLS.CLIENT_QUERY_MODE;

function ClientDigitalAnalyst() {
  const colorTheme = useColorTheme();
  const navigate = useNavigate();

  const clientData = useClientData();

  const [isReady, setIsReady] = React.useState(false);
  const [preSelectedDocs, setPreSelectedDocs] = React.useState(null);

  const onGoBack = () => navigate(ROUTES.home);

  React.useEffect(() => {
    const docs = clientData.ceoCommentDocs;
    setPreSelectedDocs(docs);
    setIsReady(true);

    scrollToTop();
  }, []);

  if (!isReady) {
    return (
      <PageWrapper>
        <Header />
        <Body withFooter>
          <Page width={'600px'}>
            <BackArrowButton onClick={onGoBack} />
            <Container top={99} centerAll bottom={99}>
              <SvgLoadingIcon type={SVG_LOADING_ICON_TYPES.bars} color={colorTheme.text} />
              <Container left={8}>
                <Body5>Loading...</Body5>
              </Container>
            </Container>
          </Page>
        </Body>
      </PageWrapper>
    );
  }

  return (
    <PageWrapper>
      <Header />
      <Body withFooter>
        <NewQueryTool
          onGoBack={onGoBack}
          isQueryMode
          preSelectedTickers={null}
          preSelectedDocs={preSelectedDocs}
          tool={tool}
          isCustomDocsMode
        />
      </Body>
    </PageWrapper>
  );
}

export default ClientDigitalAnalyst;
