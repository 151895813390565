import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router';
import { ROUTES } from '../../constants/routes';
import { scrollToTop } from '../../utils/usefulFuncs';
import { gridTableAnalysisActions } from '../../constants/actionTypes';
import { useDispatch } from 'react-redux';
import { BuildGridModeTable } from '../gridMode/BuildGridModeTable';
import { RunGridModeTable } from '../gridMode/RunGridModeTable';
import { useClientData } from '../../hooks/features/useClientData';
import { GRID_MODES } from '../gridMode';

const PageWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

function buildClientModeData(docMode, clientData) {
  if (docMode === GRID_MODES.client) {
    return {
      columns: clientData.peerAnalysisDocs,
    };
  }
  if (docMode === GRID_MODES.clientPeerAnalysis) {
    return {
      columns: clientData.peerAnalysisDocs,
    };
  }

  return null;
}

function TableAnalysisTool({ docMode }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [mode, setMode] = React.useState('build');

  const clientData = useClientData();

  const onGoBack = () => navigate(ROUTES.home);
  const onGoBackBuildMode = () => setMode('build');

  const onRunAnalysis = (data) => {
    dispatch({
      type: gridTableAnalysisActions.initTableRows,
    });
    setMode('run');
  };

  React.useEffect(() => {
    return () => {
      dispatch({
        type: gridTableAnalysisActions.clearTable,
      });
    };
  }, []);

  React.useEffect(() => {
    const clientModeData = buildClientModeData(docMode, clientData);
    if (clientModeData !== null && docMode === GRID_MODES.clientPeerAnalysis) {
      const { columns } = clientModeData;
      columns?.forEach((c, i) => {
        const payload = { index: i, documentOrDocuments: c };
        dispatch({
          type: gridTableAnalysisActions.addSelectedDocument,
          payload,
        });
      });
    }

    scrollToTop();
  }, []);

  if (mode === 'build') {
    return (
      <PageWrapper>
        <BuildGridModeTable onGoBack={onGoBack} onRunAnalysis={onRunAnalysis} docMode={docMode} />
      </PageWrapper>
    );
  }

  return (
    <PageWrapper>
      <RunGridModeTable onGoBack={onGoBackBuildMode} docMode={docMode} />
    </PageWrapper>
  );
}

export default TableAnalysisTool;
