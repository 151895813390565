import { extractIntegerAfterHash, logger } from './usefulFuncs';
import { replaceAll } from '../libs/nvstr-utils.es';

function removeTripleBackticks(str) {
  return str.replace(/```/g, '');
}

function parseStreamedJSON(jsonStream, partialEndStreamStr) {
  try {
    return JSON.parse(jsonStream);
  } catch (e) {
    if (e instanceof SyntaxError) {
      let validStr = null;
      // Identify the last valid JSON segment
      for (let i = jsonStream.length - 1; i >= 0; i--) {
        try {
          const attempt = jsonStream.slice(0, i + 1) + partialEndStreamStr;
          JSON.parse(attempt);
          validStr = attempt;
          break;
        } catch {
          // Ignore errors as we traverse
        }
      }

      if (validStr !== null) {
        logger('parser early parse success', validStr);
        return validStr;
      }
    }

    return null;
  }
}

export function parseJSONResultCompanyList(jsonMarkdownString) {
  try {
    const str = removeTripleBackticks(jsonMarkdownString.split('```json')[1]);
    if (!str) return [];
    const data = parseStreamedJSON(str, ']');
    if (!Array.isArray(data)) {
      return null;
    }
    return data.map((company) => ({
      name: company.name,
      symbol: company.symbol,
    }));
  } catch (error) {
    return null;
  }
}

export function parseFullContextFilename(v) {
  if (!v) return v;
  return v.split('#')[0];
}

export function parseContextValue(v) {
  const [throwaway, header, body] = v.split('@@@ ');
  const filename = parseFullContextFilename(header);
  const chunkIndex = extractIntegerAfterHash(header);
  const text = body;
  return {
    filename,
    chunkIndex,
    text,
  };
}

export function checkIsContextLineMatch(citationItem, contextItem) {
  if (!contextItem || !citationItem) return false;

  const { chunkIndex: citationChunkIndex, filename: citationFilename } = citationItem;
  const { chunkIndex: contextChunkIndex, filename: contextFilename } = contextItem;

  if (citationChunkIndex === contextChunkIndex && citationFilename === contextFilename) {
    return true;
  }
  if (citationChunkIndex === contextChunkIndex && citationFilename === hackFilenameForAPI(contextFilename)) {
    return true;
  }
  return false;
}

export function checkIsContextHighlighted(contextItem, highlightContextItem) {
  if (!highlightContextItem) return false;

  const { chunkIndex, filename } = contextItem;
  const { chunkId, doc } = highlightContextItem;

  if (chunkIndex === chunkId && filename?.includes(doc.filename)) {
    return true;
  }
  if (chunkIndex === chunkId && filename?.includes(hackFilenameForAPI(doc.filename))) {
    return true;
  }
  return false;
}

export function formatContext(value) {
  if (!value) return [];

  return value?.split('###');
}

function removeConsecutiveWhitespaceReverse(str) {
  if (!str) return [];
  let results = [];
  let parts = str.split(/\s+/);

  for (let i = parts.length - 1; i >= 0; i--) {
    let trimmedString = parts.slice(i).join(' ').trim();
    results.push(trimmedString);
  }

  return results;
}

function compareFilenames(str1, str2) {
  const versions = removeConsecutiveWhitespaceReverse(str1);
  return versions.includes(str2);
}

function hackFilenameForAPI(filename) {
  return replaceAll(filename, '_', ' ');
}

function getAllFilenameContext(filename, formatted) {
  const items = [];

  formatted.forEach((line, i) => {
    const { filename: contextFilename, text } = parseContextValue(line);
    if (compareFilenames(contextFilename, hackFilenameForAPI(filename))) {
      items.push(text);
    }
  });
  return items;
}

export function getCitationContext(chunkIndex, filename, context) {
  const formatted = formatContext(context);
  const found = [];
  formatted.forEach((line, i) => {
    const { filename: lineFileName, chunkIndex: lineChunkIndex, text } = parseContextValue(line);
    if (checkIsContextLineMatch({ filename: lineFileName, chunkIndex: lineChunkIndex }, { filename, chunkIndex })) {
      found.push({
        filename,
        chunkIndex,
        text,
      });
    }
  });

  return found;
}

export function getContextItem(item, context) {
  const formatted = formatContext(context);
  let found = null;
  formatted.forEach((line, i) => {
    const { filename, chunkIndex, text } = parseContextValue(line);
    const contextItem = { chunkIndex, filename };

    if (checkIsContextHighlighted(contextItem, item)) {
      const { filename, chunkIndex, text } = parseContextValue(line);
      found = {
        filename,
        chunkIndex,
        text,
      };
    }
  });

  if (found === null) {
    const { doc } = item;
    const filename = doc?.filename;
    const lines = getAllFilenameContext(filename, formatted);
    return { allContextData: lines };
  } else {
    // console.log('found', found);
  }

  return { contextData: found };
}
