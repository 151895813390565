import React from 'react';
import styled from 'styled-components';
import { Body1, Body5, Container, Page, SVG_LOADING_ICON_TYPES, SvgLoadingIcon } from '../../libs/nvstr-common-ui.es';
import { FooterSeparator } from '../../components/layout/Body';
import BackArrowButton from '../../components/buttons/BackArrowButton';
import { SkeletonButton } from '../../components/buttons/SkeletonButton';
import { AdvancedSettingsButton } from '../DigitalAnalyst/AdvancedSettings';
import { gridTableAnalysisActions } from '../../constants/actionTypes';
import { useDispatch } from 'react-redux';
import { AdvancedSettingsFields } from '../../hooks/application/useAdvancedSettings';
import { TrackingEvent } from '../../libs/nvstr-utils.es';
import { DEFAULT_CREDIT_MODE_PROMPTS, GRID_MODES } from './index';
import { FONT } from '../../constants/app';
import { useColorTheme } from '../../hooks';
import { useTableAnalysis } from '../../hooks/features/useTableAnalysis';
import { handle400Statuses, sendApiRequest } from '../../services/api';
import { logNetRequest } from '../../utils/usefulFuncs';
import { DocumentSelectorCell } from '../TableAnalysis/tableComponents/DocumentSelectorCell';
import { PromptRow } from '../TableAnalysis/tableComponents/PromptRow';
import { AddPromptRow } from '../TableAnalysis/tableComponents/AddPromptRow';
import ErrorText from '../../components/UI/ErrorText';
import { FlatButton } from '../../components/buttons';
import { ManageFavoriteQuestionSharing } from '../TableAnalysis/modes/ManageFavoriteQuestionSharing';
import { Header } from './Header';
import { NewDocumentSelectionModal } from '../DigitalAnalyst/components/DocumentSelectionModal';
import { AI_TOOLS, DOC_SELECT_MODES } from '../../constants';
import { useCurrentUserToolsAvailable } from '../../hooks/user/useCurrentUser';

const Wrapper = styled.div``;
const TableWrapper = styled.div`
  padding-top: 24px;
  min-height: 480px;

  table {
    width: 100%;

    th,
    td {
      color: ${({ theme }) => theme.themeColors.text};
      border: 1px solid ${({ theme }) => theme.themeColors.lowContrastBorder};
      border-radius: 1px;
    }

    th {
      font-size: 12px;
      line-height: 14px;
      font-family: ${() => FONT.bold};
      min-width: 120px;
      padding: 0;
    }

    td {
      padding: 12px 8px 12px 20px;
      font-size: 12px;
      line-height: 14px;
      font-family: ${() => FONT.normal};
    }
  }
`;

const defaultAdvancedSettingsFields = [
  // AdvancedSettingsFields.seeContext,
  AdvancedSettingsFields.maxContextLength,
  AdvancedSettingsFields.maxAnswerLength,
  AdvancedSettingsFields.model,
  AdvancedSettingsFields.includeInferredMetadata,
  AdvancedSettingsFields.maxContextChunks,
  AdvancedSettingsFields.top_p,
  AdvancedSettingsFields.seed,
  AdvancedSettingsFields.temperature,
];

function buildCustomDefaultPrompt(id, display, prompt, isDiffMode) {
  return {
    id: 'custom-prompt-' + id,
    display_label: display,
    prompt: prompt,
    is_default: true,
    is_diff_mode: !!isDiffMode,
    is_my_prompt: false,
    is_shared: false,
    block_editting: true,
  };
}

function _isDisabledFromDocs(selectedDocuments) {
  return selectedDocuments.length === 1 && selectedDocuments[0] === null;
}

const selectDefaultDocSelectMode = (docMode) => {
  if (docMode === GRID_MODES.fed) {
    return DOC_SELECT_MODES.speaker;
  }
  if (docMode === GRID_MODES.liquidityTerms) {
    return DOC_SELECT_MODES.docType;
  }
  if (docMode === GRID_MODES.credit) {
    return DOC_SELECT_MODES.docType;
  }
  if (docMode === GRID_MODES.misc) {
    return DOC_SELECT_MODES.ticker;
  }
  if (docMode === GRID_MODES.ppm) {
    return DOC_SELECT_MODES.docType;
  }
  if (docMode === GRID_MODES.client) {
    return DOC_SELECT_MODES.client;
  }

  return DOC_SELECT_MODES.ticker;
};

const selectAvailableDocSelectModes = (docMode, { isCompanyTaggingEnabled }) => {
  const companyTagFallback = isCompanyTaggingEnabled ? [DOC_SELECT_MODES.companyTag] : [];
  if (docMode === GRID_MODES.fed) {
    return [DOC_SELECT_MODES.speaker, DOC_SELECT_MODES.docType];
  }
  if (docMode === GRID_MODES.credit) {
    return [DOC_SELECT_MODES.docType, ...companyTagFallback];
  }
  if (docMode === GRID_MODES.liquidityTerms) {
    return [DOC_SELECT_MODES.docType];
  }
  if (docMode === GRID_MODES.misc) {
    return [DOC_SELECT_MODES.ticker, DOC_SELECT_MODES.docType, ...companyTagFallback];
  }
  if (docMode === GRID_MODES.ppm) {
    return [DOC_SELECT_MODES.docType, ...companyTagFallback];
  }
  if (docMode === GRID_MODES.client) {
    return [DOC_SELECT_MODES.client];
  }

  return [DOC_SELECT_MODES.ticker, DOC_SELECT_MODES.docType, ...companyTagFallback];
};

const ColumnDocSelectionModal = ({
  index,
  docMode,
  docs: selectedDocs,
  prevActionCache,

  handleAddDocument,
  handleRemoveDocument,

  onAction,
  onDismiss,
}) => {
  const toolsAvailable = useCurrentUserToolsAvailable();
  const isCompanyTaggingEnabled = toolsAvailable.includes(AI_TOOLS.COMPANY_TAGGING);

  const [docSelectModes] = React.useState(selectAvailableDocSelectModes(docMode, { isCompanyTaggingEnabled }));

  const bindIndexToAddDocument = (i) => handleAddDocument(i);
  const bindIndexToRemoveDocument = (i) => handleRemoveDocument(i);

  return (
    <NewDocumentSelectionModal
      heading={`Select Document(s) for column ${index + 1}`}
      docMode={docMode}
      prevActionCache={prevActionCache}
      docSelectModes={docSelectModes}
      defaultActiveMode={selectDefaultDocSelectMode(docMode)}
      selectedDocs={selectedDocs}
      onAction={onAction}
      handleAddDocument={bindIndexToAddDocument(index)}
      handleAddDocumentSpeakerSearch={bindIndexToAddDocument(index)}
      handleRemoveDocument={bindIndexToRemoveDocument(index)}
      onDismiss={onDismiss}
    />
  );
};

function filterForDefaultPrompts(favQs) {
  return favQs.filter((q) => q.is_default);
}

const BuildTableQuestionDocumentSelector = ({ onRunAnalysis, docMode }) => {
  const dispatch = useDispatch();
  const colorTheme = useColorTheme();

  const [prevActionCache, setPrevActionCache] = React.useState({});

  const [docSelectionColumnIndex, setDocSelectionColumnIndex] = React.useState(null);
  const showSelector = docSelectionColumnIndex !== null;

  const [initializing, setInitializing] = React.useState(true);
  const [error, setError] = React.useState(null);
  const [initError, setInitError] = React.useState(null);

  const tableState = useTableAnalysis();
  const { prompts, selectedDocuments } = tableState;

  const numberOfColumns = selectedDocuments.length;
  const disableRunAnalysis = _isDisabledFromDocs(selectedDocuments);

  const dismissDocSelection = () => setDocSelectionColumnIndex(null);

  // init
  React.useEffect(() => {
    const getFavorites = async (userMode) => {
      const URL = 'v1/genai_favorite_prompts?user_mode=' + userMode;
      const { status, data, error } = await sendApiRequest('get', URL);
      logNetRequest(status, data);
      handle400Statuses(status);
      if (status === 200) {
        const prompts = data.prompts;
        const defaults = prompts.filter((p) => p.is_default);
        return {
          defaultPrompts: defaults,
          favQs: prompts.filter((p) => !p.is_default),
        };
      } else {
        setInitError(true);
        return {};
      }
    };

    const initFedMode = async () => {
      const { favQs, defaultPrompts } = await getFavorites(docMode);
      dispatch({
        type: gridTableAnalysisActions.replaceFavoriteQuestions,
        payload: [...favQs, ...defaultPrompts],
      });
      dispatch({
        type: gridTableAnalysisActions.replacePrompts,
        payload: defaultPrompts,
      });
      setInitializing(false);
    };
    const initPPMMode = async () => {
      const { favQs, defaultPrompts } = await getFavorites(docMode);
      dispatch({
        type: gridTableAnalysisActions.replaceFavoriteQuestions,
        payload: [...favQs, ...defaultPrompts],
      });
      dispatch({
        type: gridTableAnalysisActions.replacePrompts,
        payload: defaultPrompts,
      });
      setInitializing(false);
    };
    const initCreditMode = async () => {
      const { favQs, defaultPrompts } = await getFavorites(docMode);
      dispatch({
        type: gridTableAnalysisActions.replaceFavoriteQuestions,
        payload: [...favQs, ...defaultPrompts],
      });
      dispatch({
        type: gridTableAnalysisActions.replacePrompts,
        payload: [...DEFAULT_CREDIT_MODE_PROMPTS, ...defaultPrompts],
      });
      setInitializing(false);
    };

    const initLiquidityTermsMode = async () => {
      const { favQs, defaultPrompts } = await getFavorites(docMode);

      dispatch({
        type: gridTableAnalysisActions.replaceFavoriteQuestions,
        payload: [...favQs, ...defaultPrompts],
      });
      dispatch({
        type: gridTableAnalysisActions.replacePrompts,
        payload: defaultPrompts,
      });
      setInitializing(false);
    };
    const initGenericMode = async () => {
      const { favQs, defaultPrompts } = await getFavorites(docMode);
      dispatch({
        type: gridTableAnalysisActions.replaceFavoriteQuestions,
        payload: [...favQs, ...defaultPrompts],
      });
      dispatch({
        type: gridTableAnalysisActions.replacePrompts,
        payload: defaultPrompts,
      });
      setInitializing(false);
    };
    const initCustomClientModes = async () => {
      const { favQs, defaultPrompts } = await getFavorites(docMode);
      dispatch({
        type: gridTableAnalysisActions.replaceFavoriteQuestions,
        payload: [...favQs, ...defaultPrompts],
      });
      dispatch({
        type: gridTableAnalysisActions.replacePrompts,
        payload: defaultPrompts,
      });
      setInitializing(false);
    };
    if (docMode === GRID_MODES.fed) initFedMode();
    if (docMode === GRID_MODES.credit) initCreditMode();
    if (docMode === GRID_MODES.ppm) initPPMMode();
    if (docMode === GRID_MODES.liquidityTerms) initLiquidityTermsMode();
    if (docMode === GRID_MODES.misc) initGenericMode();
    if (docMode === GRID_MODES.clientPeerAnalysis) initCustomClientModes();
    if (docMode === GRID_MODES.client) initCustomClientModes();
  }, []);

  const onAction = (type, value) => {
    if (type === 'ticker') {
      // const cache = { ...prevActionCache };
      // cache.tickers = [value, ...(prevActionCache.tickers || [])];
      // setPrevActionCache(cache);
    }
  };

  const handleDocSelect = (i) => (documentOrDocuments) => {
    dispatch({
      type: gridTableAnalysisActions.addSelectedDocument,
      payload: { index: i, documentOrDocuments },
    });

    if (Array.isArray(documentOrDocuments)) {
      documentOrDocuments.forEach((d) => {
        TrackingEvent.create('Add Document To Table', {
          Id: d?.id,
          Filename: d?.filename,
        });
      });
    } else {
      TrackingEvent.create('Add Document To Table', {
        Id: documentOrDocuments?.id,
        Filename: documentOrDocuments?.filename,
      });
    }

    setError(null);
  };

  const handleDocRemove = (i) => (documentOrDocuments) => {
    dispatch({
      type: gridTableAnalysisActions.removeSelectedDocument,
      payload: { index: i, documentOrDocuments },
    });

    setError(null);
  };

  const handleDocColumnClear = (i) => () => {
    dispatch({
      type: gridTableAnalysisActions.removeSelectedDocument,
      payload: { index: i, clearAll: true },
    });

    setError(null);
  };

  const handleRunAnalysisClick = () => {
    if (prompts.length === 0) {
      setError('Please enter or select questions.');
      return;
    }
    if (disableRunAnalysis) {
      setError('Please select documents to analyze.');
      return;
    }
    setError(null);

    TrackingEvent.create('Run Analysis', {});

    onRunAnalysis({
      prompts,
      docs: selectedDocuments.filter((d) => d !== null),
    });
  };

  const onOpenDocSelect = (i) => setDocSelectionColumnIndex(i);

  if (initError) {
    return (
      <Container top={80} bottom={100}>
        <Container centerAll>
          <Body1>Something went wrong, please refresh and try again.</Body1>
        </Container>
      </Container>
    );
  }

  if (initializing) {
    return (
      <Container top={80} bottom={100}>
        <Container centerAll>
          <SvgLoadingIcon type={SVG_LOADING_ICON_TYPES.bars} color={colorTheme.text} />
          <Container left={16}>
            <Body1>Loading...</Body1>
          </Container>
        </Container>
      </Container>
    );
  }

  return (
    <TableWrapper>
      {showSelector && (
        <ColumnDocSelectionModal
          index={docSelectionColumnIndex}
          docMode={docMode}
          prevActionCache={prevActionCache}
          docs={selectedDocuments[docSelectionColumnIndex]}
          handleAddDocument={handleDocSelect}
          handleRemoveDocument={handleDocRemove}
          onAction={onAction}
          onDismiss={dismissDocSelection}
        />
      )}
      <table>
        <tbody>
          <tr>
            <td>
              <Body5 bold>Question</Body5>
            </td>
            {selectedDocuments.map((s, i) => (
              <DocumentSelectorCell
                key={i}
                index={i}
                docs={s}
                onOpenDocSelect={onOpenDocSelect}
                removeDocs={handleDocColumnClear(i)}
              />
            ))}
          </tr>
          {prompts.map((p, i) => (
            <PromptRow key={i} index={i} prompt={p} numberOfColumns={numberOfColumns} docMode={docMode} />
          ))}
          <tr>
            <td colSpan={5}>
              <AddPromptRow />
            </td>
          </tr>
        </tbody>
      </table>
      <Container top={24} style={{ textAlign: 'center' }}>
        {error && (
          <Container bottom={16} style={{ textAlign: 'center' }}>
            <ErrorText>{error}</ErrorText>
          </Container>
        )}
        <FlatButton fullWidth onClick={handleRunAnalysisClick} disabled={disableRunAnalysis}>
          Run Analysis
        </FlatButton>
      </Container>
    </TableWrapper>
  );
};

const TableFooterOptions = ({ docMode }) => {
  const dispatch = useDispatch();
  const [mode, setMode] = React.useState(null);

  const handleClearImportedTableClick = () => {
    dispatch({
      type: gridTableAnalysisActions.clearTable,
    });
  };
  const handleLoadAllFavsClick = () => {
    dispatch({
      type: gridTableAnalysisActions.loadAllFavs,
    });
  };
  const handleManageSharedQClick = () => {
    setMode('manage-shares');
  };

  return (
    <Container bottom={4}>
      <FooterSeparator />
      <Container row spaceBetween>
        <Container row>
          {mode === 'manage-shares' && (
            <Container top={24}>
              <ManageFavoriteQuestionSharing onDismiss={() => setMode(null)} />
            </Container>
          )}
          <Container left={16}>
            <SkeletonButton onClick={handleManageSharedQClick}>Manage Shared Questions</SkeletonButton>
          </Container>
          <Container left={16}>
            <SkeletonButton onClick={handleLoadAllFavsClick}>Load All Favorites</SkeletonButton>
          </Container>
        </Container>
        <Container left={16}>
          <SkeletonButton onClick={handleClearImportedTableClick}>Reset Table</SkeletonButton>
        </Container>
      </Container>
    </Container>
  );
};

export const BuildGridModeTable = ({ onGoBack, onRunAnalysis, docMode }) => {
  React.useEffect(() => {
    TrackingEvent.create('View Table Builder', {
      'Doc Mode': docMode,
    });
  }, []);

  return (
    <Wrapper>
      <Page width={'100%'}>
        <Container>
          <BackArrowButton onClick={onGoBack} />
          <AdvancedSettingsButton fields={defaultAdvancedSettingsFields} />

          <Container top={48}>
            <Header docMode={docMode} />
          </Container>
          <BuildTableQuestionDocumentSelector onRunAnalysis={onRunAnalysis} docMode={docMode} />
        </Container>
        <TableFooterOptions docMode={docMode} />
      </Page>
    </Wrapper>
  );
};
