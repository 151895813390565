import { NODE_HANDLE_IDS, NODE_TYPES } from '../containers/flow/nodes';
import { logger } from '../utils/usefulFuncs';
import { parseJSONResultCompanyList } from '../utils/result';
import { AdvancedSettingsFields } from '../hooks/application/useAdvancedSettings';
import { streamResult } from '../hooks/features/useStreamingResult';

export class NodeData {
  constructor(node, nodes, edges) {
    this.node = node;
    this.nodes = nodes;
    this.edges = edges;

    const children = this.findChildrenNodes();
    const actionNode = this.getActionNode();
    const parentNodes = this.findDirectParentTargetNodes();
    const advancedSettings = this.getAdvancedSettingsFromNode();
    const companies = this.getCompanyList();
    const selectedDocs = this.getDocumentList();
    const settingsList = this.getSettingsList();

    const nodeContext = this.gatherNodeContext();
    const extraContext = this.gatherContextFromNodes(nodeContext);

    this.data = {
      children,
      parentNodes,

      actionNode,

      advancedSettings,

      selectedDocs,
      companies,

      settingsList,

      extraContext,

      nextNodes: this.getNextNodes(),
    };
  }

  findDirectParentTargetNodes = () => {
    const directEdges = this.edges.filter(
      (edge) => edge.target === this.node.id && edge.targetHandle === NODE_HANDLE_IDS.executionFlowTarget
    );
    const connectedNodes = directEdges.map((edge) => this.nodes.find((n) => n.id === edge.source));
    return connectedNodes.filter(Boolean).filter((n) => n.type !== NODE_TYPES.StartNode);
  };

  findChildrenNodes = () => {
    const childEdges = this.edges.filter(
      (edge) =>
        edge.target === this.node.id &&
        ![NODE_HANDLE_IDS.executionFlowTarget, NODE_HANDLE_IDS.executionFlowSource].includes(edge.targetHandle)
    );
    const childNodes = childEdges.map((edge) => this.nodes.find((n) => n.id === edge.source));
    return childNodes.filter(Boolean);
  };

  // rename
  gatherNodeContext() {
    const _this = this;
    const contextNodes = [];

    function traceParents(currentNode) {
      const incomingEdges = _this.edges.filter((edge) => edge.target === currentNode.id);
      incomingEdges.forEach((edge) => {
        const parentNode = NodeUtils.findNodeById(edge.source, _this.nodes);
        if (
          parentNode &&
          parentNode.data &&
          (parentNode.data.answer || parentNode.data.answers || parentNode.data.context)
        ) {
          contextNodes.push(parentNode);
        }
        if (parentNode) {
          traceParents(parentNode);
        }
      });
    }

    traceParents(_this.node);
    return contextNodes;
  }

  gatherContextFromNodes(nodesWithContext) {
    let gatheredContext = '';
    nodesWithContext.forEach((node) => {
      const { answer, answers } = node.data;
      if (answer) {
        gatheredContext += ' ';
        gatheredContext += answer;
      }
      if (answers) {
        Object.entries(answers).forEach((entry) => {
          const [companyName, answer] = entry;
          gatheredContext += ' ';
          gatheredContext += companyName;
          gatheredContext += ' ';
          gatheredContext += answer;
        });
      }
    });
    return gatheredContext;
  }

  getActionNode = (forceNode) => {
    const _node = forceNode || this.node;
    const edge = this.edges.find(
      (connection) => connection.target === _node.id && connection.sourceHandle === NODE_HANDLE_IDS.ForEachAction
    );
    if (!edge) return null;

    const nodeId = edge.source;
    return this.nodes.find((node) => node.id === nodeId) || null;
  };

  getAllInputNodes = () => {
    const matchingEdges = this.edges.filter(
      (connection) =>
        connection.target === this.node.id && connection.sourceHandle !== NODE_HANDLE_IDS.executionFlowSource
    );
    if (this.edges.length === 0) return null;

    const matchingNodes = this.nodes.filter((node) => matchingEdges.some((e) => e.source === node.id));
    return matchingNodes;
  };

  getDocumentsFromNodes = (matchingNodes) => {
    const list = [];
    matchingNodes.forEach((node) => {
      try {
        let _node = node;
        if (node.type === NODE_TYPES.ForEachCompanyNode) {
          _node.data.selectedDocs.forEach((d) => {
            list.push(d);
          });
        }
        if ('selectedDocs' in _node.data) {
          _node.data.selectedDocs.forEach((d) => {
            list.push(d);
          });
        }
      } catch (e) {
        logger('error parsing doc list from node', e);
      }
    });
    return list;
  };

  getSettingsFromNodes = (matchingNodes, nodes, edges) => {
    const settings = [];
    matchingNodes.forEach((node) => {
      let _node = node;
      if (node.type === NODE_TYPES.ForEachCompanyNode) {
        _node = this.getActionNode(node);
      }
      try {
        const settings = _node.data.settings;
        settings?.forEach((d) => {
          settings.push(d);
        });
      } catch (e) {
        logger('error parsing company list node', e);
      }
    });
    return settings;
  };

  getCompaniesFromNodes = (matchingNodes, nodes, edges) => {
    const companies = [];
    matchingNodes.forEach((node) => {
      let _node = node;
      if (node.type === NODE_TYPES.GenerateCompanyListNode) {
        try {
          const answer = node.data.answer;
          const list = parseJSONResultCompanyList(answer);
          if (list) {
            list.forEach((c) => companies.push(c));
          }
        } catch (e) {
          logger('error parsing company list node', e);
        }
      }
      if (node.type === NODE_TYPES.ForEachCompanyNode) {
        _node = this.getActionNode(node);

        try {
          const answer = _node.data.answer;
          const list = parseJSONResultCompanyList(answer);
          if (list) {
            list.forEach((c) => companies.push(c));
          }
        } catch (e) {
          logger('error parsing company list node', e);
        }
      }
      if (node.type === NODE_TYPES.CompanyListNode) {
        node.data.selectedCompanies.forEach((d) => {
          companies.push(d);
        });
      }
    });
    return companies;
  };

  getAdvancedSettingsFromNode = (node, nodes, edges) => {
    const inputNodes = this.getAllInputNodes(node, nodes, edges);
    if (!inputNodes) {
      return null;
    }
    const settingsNodes = inputNodes.filter((node) => node.type === NODE_TYPES.AdvancedSettingsNode);
    const concatData = {};
    settingsNodes.forEach((node) => {
      const nodeData = node.data;
      const entries = Object.entries(nodeData);
      const validSettings = Object.values(AdvancedSettingsFields);
      entries.forEach((entry) => {
        const [key, value] = entry;
        if (validSettings.includes(key)) {
          concatData[key] = value;
        }
      });
    });
    return concatData;
  };

  getSettingsList = () => {
    const matchingNodes = this.getAllInputNodes(this.node, this.nodes, this.edges);
    if (!matchingNodes) return null;
    return this.getSettingsFromNodes(matchingNodes, this.nodes, this.edges);
  };

  getDocumentList = () => {
    const matchingNodes = this.getAllInputNodes(this.node, this.nodes, this.edges);
    if (!matchingNodes) return null;
    return this.getDocumentsFromNodes(matchingNodes, this.nodes, this.edges);
  };

  getCompanyList = () => {
    const matchingNodes = this.getAllInputNodes(this.node, this.nodes, this.edges);
    if (!matchingNodes) return null;
    return this.getCompaniesFromNodes(matchingNodes, this.nodes, this.edges);
  };

  getNextNodes = () => {
    const matchingEdges = this.edges.filter(
      (connection) =>
        connection.source === this.node.id && connection.targetHandle === NODE_HANDLE_IDS.executionFlowTarget
    );
    if (this.edges.length === 0) return null;

    const matchingNodes = this.nodes.filter((node) => matchingEdges.some((e) => e.target === node.id));
    return matchingNodes;
  };

  getConditionalNextNodes = (node, nodeActions) => {
    const answer = node.data.answer;
    try {
      if (answer.toUpperCase() === 'YES') {
        const matchingEdges = this.edges.filter(
          (connection) =>
            connection.source === this.node.id && connection.sourceHandle === NODE_HANDLE_IDS.executionFlowSourceTrue
        );
        if (this.edges.length === 0) return null;

        const matchingNodes = this.nodes.filter((node) => matchingEdges.some((e) => e.target === node.id));
        return matchingNodes;
      } else {
        const matchingEdges = this.edges.filter(
          (connection) =>
            connection.source === this.node.id && connection.sourceHandle === NODE_HANDLE_IDS.executionFlowSourceFalse
        );
        if (this.edges.length === 0) return null;

        const matchingNodes = this.nodes.filter((node) => matchingEdges.some((e) => e.target === node.id));
        return matchingNodes;
      }
    } catch (e) {
      const error = 'Something went wrong.';
      logger('error parsing YES/NO answer', e);
      node.data.error = error;
      nodeActions?.updateData({ error });
      this.error = true;
      return false;
    }
  };
}

class _NodeUtils {
  findNodeById = (id, nodes) => {
    return nodes.find((n) => n.id === id);
  };
}

export const NodeUtils = new _NodeUtils();

export class NodeDataUpdater {
  constructor(muteableNode, nodeActions) {
    this.muteableNode = muteableNode;
    this.nodeActions = nodeActions;
  }

  handleError = (error) => {
    this.muteableNode.data.error = error;
    this.nodeActions.updateData({ error });
    logger('error getting result', this.muteableNode);
    this.error = true;
  };

  handleStreamAnswer = (resultId) => {
    const onOpen = () => {
      this.muteableNode.data.answer = 'Working...';
      this.nodeActions.updateData({ answer: 'Working...', isComplete: false });
    };
    const onMessage = (answer) => {
      this.muteableNode.data.answer = answer;
      this.nodeActions.updateData({ answer });
    };
    const onRefiningStart = () => {
      this.muteableNode.data.isRefining = true;
      this.nodeActions.updateData({ isRefining: true });
    };
    const onError = (error) => {
      this.muteableNode.data.error = error;
      this.nodeActions.updateData({ error });
    };
    const onComplete = ({ answer }) => {
      this.muteableNode.data.answer = answer;
      this.nodeActions.updateData({ answer, isComplete: true });
    };
    const handlers = {
      onOpen,
      onMessage,
      onRefiningStart,
      onError,
      onComplete,
    };
    const options = { enableQualityCheck: true };
    return streamResult(resultId, handlers, options);
  };

  streamCompleteHandler = (resultId, onCompleteCB) => {
    const onOpen = () => {
      this.muteableNode.data.answer = 'Working...';
      this.nodeActions.updateData({ answer: 'Working...', isComplete: false });
    };
    const onMessage = (answer) => {};
    const onRefiningStart = () => {};
    const onError = (error) => {
      this.muteableNode.data.error = error;
      this.nodeActions.updateData({ error });
    };
    const onComplete = ({ answer }) => {
      this.muteableNode.data.answer = answer;
      this.nodeActions.updateData({ answer });
      onCompleteCB(answer);
    };
    const handlers = {
      onOpen,
      onMessage,
      onRefiningStart,
      onError,
      onComplete,
    };
    const options = { enableQualityCheck: true };
    return streamResult(resultId, handlers, options);
  };
}
