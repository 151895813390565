import React from 'react';
import { handle400Statuses, sendApiRequest } from '../../services/api';
import { logNetRequest } from '../../utils/usefulFuncs';
import { FlatButton } from '../../components/buttons';
import styled from 'styled-components';
import { Body5, Body7, Container, Delete } from '../../libs/nvstr-common-ui.es';
import { Edit } from '../../assets/icons/svgs/Edit';
import { useDispatch } from 'react-redux';
import { useCurrentUserToolsAvailable } from '../../hooks/user/useCurrentUser';
import { AI_TOOLS, UPLOAD_DOC_TYPE_OPTIONS } from '../../constants';
import { convertHexToRGBA } from '../../libs/nvstr-utils.es';
import { TickerSelect } from '../DigitalAnalyst/components/TickerSelect';
import { SkeletonButton } from '../../components/buttons/SkeletonButton';
import { SearchAutocomplete } from '../DigitalAnalyst/components/SearchAutocomplete';
import { liveDocsActions } from '../../constants/actionTypes';
import { parseDocGroup } from '../../utils/doc';
import { ROUTES } from '../../constants/routes';
import { CloseButton } from '../../components/buttons/CloseButton';

const DetailViewWrapper = styled.div`
  padding: 16px 36px;
  margin-bottom: 13px;

  * {
    word-break: break-all;
  }
`;
const FieldsWrapper = styled.div`
  display: grid;
  grid-template-columns: max-content max-content;
`;
const ActionsWrapper = styled.div`
  padding: 12px 0 0 0;

  display: flex;
  flex-direction: row;
  gap: 16px;

  button {
    min-width: 200px;
  }
`;
const SaveButtonWrapper = styled.div`
  button {
    padding: 6px 58px;
  }
`;
const ViewOriginalButtonWrapper = styled.div`
  button {
    padding: 6px 24px;
  }
`;
const DeleteButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  button {
    padding: 6px 16px;

    border-color: ${({ theme }) => theme.themeColors.error} !important;

    * {
      color: ${({ theme }) => theme.themeColors.error} !important;
    }

    &:hover {
      background-color: ${({ theme }) => theme.themeColors.error} !important;
      color: ${({ theme }) => theme.themeColors.componentNoOpacity} !important;

      * {
        color: ${({ theme }) => theme.themeColors.componentNoOpacity} !important;
      }
    }
  }

  button:hover {
    * {
      color: ${({ theme }) => theme.themeColors.text} !important;
    }
  }
`;

const EditButtonWrapper = styled.div`
  height: 26px;
  padding: 5px 8px 0 24px;

  svg {
    height: 14px;
    width: 14px;

    path {
      fill: ${({ theme }) => theme.themeColors.text};
    }
  }
`;
const DeleteFieldButtonWrapper = styled.div`
  height: 26px;
  padding: 5px 8px 0 8px;

  svg {
    height: 14px;
    width: 14px;

    path {
      fill: ${({ theme }) => theme.themeColors.text};
    }
  }
`;

const DeleteButton = ({ onClick, value }) => {
  const hc = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onClick();
  };

  if (value === '') return null;
  return (
    <DeleteFieldButtonWrapper onClick={hc}>
      <Delete />
    </DeleteFieldButtonWrapper>
  );
};
const EditButton = ({ onClick, isEditting }) => {
  if (isEditting) return <div />;

  return (
    <EditButtonWrapper onClick={onClick}>
      <Edit />
    </EditButtonWrapper>
  );
};

const FieldName = styled.div`
  height: 36px;
  cursor: pointer;
`;
const FieldValue = styled.div`
  cursor: pointer;
`;

const Select = styled.select`
  outline: none;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.themeColors.text};

  font-size: 14px;
  line-height: 18px;
  color: ${({ theme }) => theme.themeColors.text};
  margin: 0;
  padding: 4px 8px;
  min-width: 300px;
  width: 100%;

  &::placeholder {
    color: ${({ theme }) => convertHexToRGBA(theme.themeColors.text, 0.6)};
  }
`;

const Input = styled.input`
  outline: none;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.themeColors.text};

  font-size: 14px;
  line-height: 18px;
  color: ${({ theme }) => theme.themeColors.text};
  margin: 0;
  padding: 4px 8px;
  min-width: 300px;
  width: 100%;

  &::placeholder {
    color: ${({ theme }) => convertHexToRGBA(theme.themeColors.text, 0.6)};
  }
`;

const formatDisplayValue = (v) => {
  if (v === 'unknown') return 'Unassigned';

  return v ? v : 'Unassigned';
};

function testAreUnsavedChanges(data, form) {
  let are = false;
  if (data.filename !== form.filename) {
    are = true;
  }
  if (data.security_symbol !== form.ticker) {
    are = true;
  }
  if ((data.doc_tag ? data.doc_tag.name : '') !== form.companyTag) {
    are = true;
  }
  if (data.doc_type !== form.docType) {
    are = true;
  }
  return are;
}

const InputField = ({ keyValue, value, onChange, onBlur }) => {
  if (keyValue === 'doc_type') {
    return (
      <Select value={value} onChange={onChange} onBlur={onBlur}>
        <option value={'unknown'}>Optional...</option>
        {UPLOAD_DOC_TYPE_OPTIONS.map((o) => (
          <option key={o}>{o}</option>
        ))}
      </Select>
    );
  }
  if (keyValue === 'security_symbol') {
    const onAddTicker = (t) => {
      onChange(t);
      setTimeout(() => {
        onBlur();
      }, 100);
    };
    const onRemoveTicker = (t) => {
      onChange('');
      setTimeout(() => {
        onBlur();
      }, 100);
    };
    return (
      <TickerSelect
        onAddTicker={onAddTicker}
        onRemoveTicker={onRemoveTicker}
        tickers={value ? [value] : []}
        multipleTickersEnabled={false}
        hideLabel
      />
    );
  }
  if (keyValue === 'company_tag') {
    const onSelect = (t) => {
      onChange(t?.id || '', t?.name || '');
      setTimeout(() => {
        onBlur();
      }, 100);
    };
    return <SearchAutocomplete onSelect={onSelect} enableCreateTag autoFocus />;
  }

  return <Input value={value} onChange={onChange} onBlur={onBlur} autoFocus />;
};

const Field = ({ keyName, keyValue, value, onChange, onDelete }) => {
  const [isEditting, setIsEditting] = React.useState(false);

  const handleEditStart = () => {
    setIsEditting(true);
  };
  const onBlur = () => {
    setIsEditting(false);
  };

  return (
    <>
      <FieldName onClick={handleEditStart}>
        <Container right={20} row verticallyCenter height={26}>
          <Body7>{keyName}:</Body7>
        </Container>
      </FieldName>
      <FieldValue onClick={handleEditStart}>
        <Container height={26} row verticallyCenter>
          <Container onClick={handleEditStart}>
            {isEditting ? (
              <InputField value={value} onChange={onChange} keyValue={keyValue} onBlur={onBlur} />
            ) : (
              <Container row verticallyCenter>
                <Body7>{formatDisplayValue(value)}</Body7>
                <EditButton isEditting={isEditting} onClick={handleEditStart} />
                {keyValue === 'company_tag' ? <DeleteButton onClick={onDelete} value={value} /> : null}
              </Container>
            )}
          </Container>
        </Container>
      </FieldValue>
    </>
  );
};

const SavedMessageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  width: 248px;

  margin-top: 8px;

  padding: 8px 12px 8px 24px;
  border-radius: 5px;
  background: ${({ theme }) => theme.themeColors.text};

  opacity: 0.4;

  * {
    color: ${({ theme }) => theme.themeColors.componentNoOpacity};
  }
`;
const CloseButtonWrapper = styled.div`
  padding: 0 8px 0 0;

  svg {
    * {
      fill: ${({ theme }) => theme.themeColors.componentNoOpacity} !important;
    }
  }
`;

export const FileDetail = ({ data, onDeleteClick }) => {
  const dispatch = useDispatch();
  const toolsAvailable = useCurrentUserToolsAvailable();
  const isCompanyTaggingEnabled = toolsAvailable.includes(AI_TOOLS.COMPANY_TAGGING) || true;

  const [filename, setFilename] = React.useState(data.filename);
  const [ticker, setTicker] = React.useState(data.security_symbol);
  const [companyTagId, setCompanyTagId] = React.useState(data.doc_tag ? data.doc_tag.id : '');
  const [companyTag, setCompanyTag] = React.useState(data.doc_tag ? data.doc_tag.name : '');
  const [docType, setDocType] = React.useState(data.doc_type);

  const unsavedChanges = testAreUnsavedChanges(data, { filename, ticker, companyTag, docType });

  const [showSavedMessage, setShowSavedMessage] = React.useState(false);

  React.useEffect(() => {
    if (showSavedMessage) {
      setTimeout(() => {
        setShowSavedMessage(false);
      }, 2400);
    }
  }, [showSavedMessage]);

  const handleFilenameChange = (e) => {
    const value = e.target.value;
    setFilename(value);
  };

  const handleTickerChange = (v) => {
    setTicker(v);
  };
  const handleCompanyTagChange = (id, name) => {
    setCompanyTagId(id);
    setCompanyTag(name);
  };
  const handleDeleteCompanyTag = () => {
    setCompanyTagId('');
    setCompanyTag('');
  };
  const handleDocumentTypeChange = (e) => {
    const value = e.target.value;
    setDocType(value);
  };
  const onHideSavedMessage = () => {
    setShowSavedMessage(false);
  };

  const handleViewOriginal = () => {
    const { id } = data;
    window.open(ROUTES.docViewer + `?id=${id}`, '_blank');
  };

  const handleQueryFile = () => {
    const { id } = data;
    const url = ROUTES.digitalAnalyst + `?doc-ids=${id}`;
    window.open(url, '_blank');
  };

  const handleSave = async () => {
    const { id } = data;
    const oldGroupName = parseDocGroup(data);
    const form = {
      name: filename,
      ticker,
      doc_tag_id: companyTagId,
    };
    if (docType !== 'unknown') {
      form.doc_type = docType;
    }
    const URL = 'v1/genai_user_doc/' + id;
    const { status, data: updatedServerDoc, error } = await sendApiRequest('PATCH', URL, form);
    logNetRequest(status, error);
    handle400Statuses(status);
    if (status === 200) {
      dispatch({
        type: liveDocsActions.editDoc,
        payload: { id, groupName: oldGroupName, updatedDoc: updatedServerDoc },
      });
      setShowSavedMessage(true);
    } else {
      const message = error || 'Something went wrong, please try again.';
      // TODO:::: add application error message
    }
  };

  const handleReset = () => {
    setFilename(data.filename);
    setTicker(data.security_symbol);
    setCompanyTag(data.doc_tag ? data.doc_tag.name : '');
    setDocType(data.doc_type);
  };

  return (
    <DetailViewWrapper>
      <FieldsWrapper>
        <Field keyName={'Filename'} keyValue={'filename'} value={filename} onChange={handleFilenameChange} />
        <Field keyName={'Document Type'} keyValue={'doc_type'} value={docType} onChange={handleDocumentTypeChange} />
        <Field keyName={'Ticker'} keyValue={'security_symbol'} value={ticker} onChange={handleTickerChange} />
        {isCompanyTaggingEnabled && (
          <Field
            keyName={'Company Tag'}
            keyValue={'company_tag'}
            value={companyTag}
            onChange={handleCompanyTagChange}
            onDelete={handleDeleteCompanyTag}
          />
        )}
      </FieldsWrapper>
      {showSavedMessage && (
        <SavedMessageWrapper top={4}>
          <Body5 bold>Updates were saved.</Body5>
          <CloseButtonWrapper>
            <CloseButton size={16} onClick={onHideSavedMessage}></CloseButton>
          </CloseButtonWrapper>
        </SavedMessageWrapper>
      )}
      <ActionsWrapper>
        {unsavedChanges && (
          <>
            <SaveButtonWrapper>
              <FlatButton onClick={handleSave}>Save</FlatButton>
            </SaveButtonWrapper>
            <SaveButtonWrapper>
              <SkeletonButton onClick={handleReset}>Reset</SkeletonButton>
            </SaveButtonWrapper>
          </>
        )}
      </ActionsWrapper>
      <ActionsWrapper>
        <DeleteButtonWrapper>
          <SkeletonButton onClick={onDeleteClick}>Delete {data.filename}</SkeletonButton>
        </DeleteButtonWrapper>
      </ActionsWrapper>

      {data.blob_id && (
        <ActionsWrapper>
          <ViewOriginalButtonWrapper>
            <SkeletonButton onClick={handleViewOriginal}>View Original File</SkeletonButton>
          </ViewOriginalButtonWrapper>
        </ActionsWrapper>
      )}

      <ActionsWrapper>
        <ViewOriginalButtonWrapper>
          <SkeletonButton onClick={handleQueryFile}>Query File</SkeletonButton>
        </ViewOriginalButtonWrapper>
      </ActionsWrapper>
    </DetailViewWrapper>
  );
};
