import { extractIntegerAfterHash } from '../utils/usefulFuncs';
import { parseFullContextFilename } from '../utils/result';

export default class ContextParser {
  constructor(contextString, index) {
    this.contextString = contextString;
    this.contextLookup = {};
    this.contextIndex = index;
  }

  _formatContext(value) {
    if (!value) return [];
    return value?.split('###');
  }

  _parseContextLine = (line) => {
    const [throwaway, header, body] = line.split('@@@ ');
    const filename = parseFullContextFilename(header);
    const chunkIndex = extractIntegerAfterHash(header);
    const text = body;
    return {
      filename,
      chunkIndex,
      text,
    };
  };

  _addLookupChunk = (filename, chunkIndex, text) => {
    if (!this.contextLookup[filename]) {
      this.contextLookup[filename] = {};
    }
    this.contextLookup[filename][chunkIndex] = text;
  };

  process = () => {
    const formatted = this._formatContext(this.contextString);
    formatted.forEach((line, i) => {
      const { filename, chunkIndex, text } = this._parseContextLine(line);
      if (filename && chunkIndex > 0 && text) {
        this._addLookupChunk(filename, chunkIndex, text);
      }
    });
    if (!window.contextLookup) window.contextLookup = {};
    window.contextLookup[this.contextIndex] = this.contextLookup;
  };

  getContextItem(citationItem) {
    const { filename, chunkIndex } = citationItem;

    const fileContext = this.contextLookup[filename];
    if (!fileContext) {
      return null;
    }
    const result = fileContext[chunkIndex] || null;
    if (!result) {
      console.log('result not found', {
        filename,
        chunkIndex,
        zLook: this.contextLookup,
        zfileContext: fileContext,
      });
    }
    return result;
  }
}
