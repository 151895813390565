import React from 'react';
import styled from 'styled-components';
import { Container } from '../../libs/nvstr-common-ui.es';
import { useSelector } from 'react-redux';
import { FooterDisclosures } from '../../components/layout/Footer';

export const Anchor = styled.a`
  text-decoration: underline;
`;

export const FooterWrapper = styled.div`
  padding: 0 24px 24px 24px;
`;

const textProps = {
  fontSize: '12px',
  textCase: 'none',
};

const links = {
  TOS: 'Terms of Service',
  PRIVACY_POLICY: 'Privacy Policy',
  BROKERCHECK: 'FINRA’s BrokerCheck website',
  FINRA: 'FINRA',
  SIPC: 'SIPC',
  FORM_CRS: 'Form CRS',
};
const linkNameToURLLookup = {
  [links.TOS]: 'https://www.tornado.com/tos',
  [links.PRIVACY_POLICY]: 'https://www.tornado.com/privacy_policy',
  [links.FINRA]: 'https://www.finra.org/',
  [links.SIPC]: 'https://www.sipc.org/',
  [links.BROKERCHECK]: 'https://brokercheck.finra.org/firm/summary/269966',
  [links.FORM_CRS]: 'https://www.tornado.com/crs',
};

function linkInjector(text) {
  const searchStrings = Object.values(links);

  // Create a regular expression pattern to match any of the searchStrings
  const pattern = new RegExp(`(${searchStrings.join('|')})`, 'g');

  // Split the text into an array of parts using the pattern
  const parts = text.split(pattern);

  // Iterate through the parts and wrap the matched strings with anchor tags
  const injectedParts = parts.map((part) => {
    if (searchStrings.includes(part)) {
      return (
        <Anchor href={linkNameToURLLookup[part]} target="_blank" rel="noopener noreferrer">
          {part}
        </Anchor>
      );
    }
    return part;
  });

  return (
    <>
      {injectedParts.map((el, i) => (
        <span key={i}>{el}</span>
      ))}
    </>
  );
}

export const AppFooter = () => {
  const footer = useSelector((state) => state.application.staticCopy?.footer);

  if (!footer) {
    return <Container></Container>;
  }

  return (
    <FooterWrapper>
      <FooterDisclosures />
    </FooterWrapper>
  );
  // return (
  //   <FooterWrapper>
  //     {footer.map((text, i) => (
  //       <Container key={i} bottom={12}>
  //         <Body5 {...textProps}>{linkInjector(text)}</Body5>
  //       </Container>
  //     ))}
  //   </FooterWrapper>
  // );
};
