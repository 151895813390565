import React from 'react';
import styled from 'styled-components';
import { Body1, Body5, Container, Page } from '../../libs/nvstr-common-ui.es';
import { useNavigate } from 'react-router';
import { ROUTES } from '../../constants/routes';
import BackArrowButton from '../../components/buttons/BackArrowButton';
import { FlatButton } from '../../components/buttons';
import { TextUploadForm } from '../form/TextUploadForm';
import { AI_TOOLS } from '../../constants';
import { useCurrentUserToolsAvailable, usePartnerTheme } from '../../hooks/user/useCurrentUser';
import WebpageUploader from '../../pages/WebpageUploader';
import { useSearchParams } from 'react-router-dom';
import { FileUploader } from './FileUploader';
import { PDFUploadForm } from '../form/PDFUploadForm';

const ButtonStyler = styled.div`
  button {
    width: 200px;
  }
`;

const UPLOAD_TYPES = {
  web: 'website',
  text: 'text',
  file: 'file',

  ocr: 'ocr',
};

function TextUploader({ onGoBack }) {
  return (
    <Page>
      <BackArrowButton onClick={onGoBack} />
      <Container top={36}>
        <h3>Text Document Uploader</h3>
        <TextUploadForm />
      </Container>
    </Page>
  );
}

function PDFDocUploader({ fedDoc, ocrDoc, adobeOcrDoc }) {
  return <PDFUploadForm fedDoc={fedDoc} ocrDoc={ocrDoc} adobeOcrDoc={adobeOcrDoc} />;
}

function OCRDocUploader({ onGoBack }) {
  return (
    <Page>
      <BackArrowButton onClick={onGoBack} />
      <Container top={36}>
        <PDFDocUploader ocrDoc />
      </Container>
    </Page>
  );
}

export const DocUploader = ({ onShowWidePage }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [uploadType, setUploadType] = React.useState(null);

  const toolsAvailable = useCurrentUserToolsAvailable();
  const isWebsiteUploadToolAvailable = toolsAvailable.includes(AI_TOOLS.WEBPAGE_UPLOAD);
  const isOCRUploadToolAvailable = toolsAvailable.includes(AI_TOOLS.EXCEL_UPLOAD);

  const partnerTheme = usePartnerTheme();
  const isCustomClient = partnerTheme === 1;

  React.useEffect(() => {
    const typeParam = searchParams.get('type');
    if (typeParam) {
      const validTypes = Object.values(UPLOAD_TYPES);
      if (validTypes.includes(typeParam)) {
        if (uploadType !== typeParam) {
          setUploadType(typeParam);
        }
      }
    }
  }, [searchParams, uploadType]);

  React.useEffect(() => {
    if (uploadType === UPLOAD_TYPES.file) {
      onShowWidePage(true);
    } else {
      onShowWidePage(false);
    }
  }, [uploadType]);

  const onGoBack = () => navigate(ROUTES.home);

  if (uploadType === UPLOAD_TYPES.text) {
    return <TextUploader onGoBack={() => setUploadType(null)} />;
  }
  if (uploadType === UPLOAD_TYPES.file) {
    return <FileUploader onGoBack={() => setUploadType(null)} />;
  }
  if (uploadType === UPLOAD_TYPES.web) {
    return <WebpageUploader onBackClick={() => setUploadType(null)} />;
  }
  if (uploadType === UPLOAD_TYPES.ocr) {
    return <OCRDocUploader onGoBack={() => setUploadType(null)} fedDoc />;
  }

  return (
    <Page>
      <BackArrowButton onClick={onGoBack} />
      <Container top={40}>
        <Body1 bold>Upload Document</Body1>
        {isCustomClient ? (
          <Container top={8}>
            <Body5>Warning: Please upload only public information to FedGPT</Body5>
          </Container>
        ) : null}
        <Container top={16} bottom={24}>
          <ButtonStyler>
            {isOCRUploadToolAvailable && (
              <Container top={24}>
                <FlatButton onClick={() => setUploadType(UPLOAD_TYPES.ocr)}>OCR</FlatButton>
              </Container>
            )}

            <Container top={24}>
              <FlatButton onClick={() => setUploadType(UPLOAD_TYPES.file)}>File</FlatButton>
            </Container>
            <Container top={24}>
              <FlatButton onClick={() => setUploadType(UPLOAD_TYPES.text)}>Text</FlatButton>
            </Container>
            {isWebsiteUploadToolAvailable && (
              <Container top={24}>
                <FlatButton onClick={() => setUploadType(UPLOAD_TYPES.web)}>Website</FlatButton>
              </Container>
            )}
          </ButtonStyler>
        </Container>
      </Container>
    </Page>
  );
};
