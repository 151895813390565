import { combineReducers } from 'redux';

import application from './application';
import clientData from './clientDocs';
import currentUser from './currentUser';
import colorTheme from './colorTheme';
import constants from './constants';
import fedSentiment from './fedSentiment';
import docs from './docs';
import gridTableAnalysis from './gridTableAnalysis';
import infoIcon from './infoIcon';
import liveDocs from './liveDocs';
import news from './news';
import recentDocs from './recentDocs';
import securities from './securities';
import watchlist from './watchlist';
import uploadStatus from './uploadStatus';
import uploadFiles from './uploadFiles';
import flow from './flow';

const rootReducer = combineReducers({
  application,
  clientData,
  colorTheme,
  constants,
  currentUser,
  docs,
  flow,
  fedSentiment,
  gridTableAnalysis,
  infoIcon,
  liveDocs,
  news,
  recentDocs,
  securities,
  watchlist,
  uploadStatus,
  uploadFiles,
});

export default rootReducer;
