import { parseQueryString, sleep } from '../libs/nvstr-utils.es';

export const getEmailAuthTokenFromUrl = () => {
  const searchParams = window.location.search;
  const searchObj = parseQueryString(searchParams);
  const auth_id = searchObj.auth_id || null;
  const token = searchObj.token || null;
  return {
    authId: auth_id,
    token,
  };
};

export const storeEmailAuthToken = () => {
  const { token, authId } = getEmailAuthTokenFromUrl();
  if (token && authId) {
    window.sessionStorage.setItem('token', token);
    window.sessionStorage.setItem('authId', authId);
    return true;
  }
  return false;
};

export const getEmailAuthTokenFromSessionStorage = () => {
  const token = window.sessionStorage.getItem('token') || null;
  const authId = window.sessionStorage.getItem('authId') || null;
  return {
    token,
    authId,
  };
};

export const getEmailAuthToken = () => {
  const { token, authId } = getEmailAuthTokenFromSessionStorage();
  if (token && authId) {
    return {
      token,
      authId,
    };
  } else {
    const { token, authId } = getEmailAuthTokenFromUrl();
    return {
      token,
      authId,
    };
  }
};

export const getEmailTokenParamsString = () => {
  try {
    const { token, authId } = getEmailAuthToken();
    if (authId && token) {
      return `?auth_id=${authId}&token=${token}`;
    }
    return '';
  } catch (e) {
    console.error(e);
    return '';
  }
};

export function getRandomDelay() {
  // Generate a random number between 2000 (2 seconds) and 5000 (5 seconds).
  return Math.floor(Math.random() * (5000 - 2000 + 1)) + 2000;
}

export function delayAndReturnBlankObject() {
  const delayInMilliseconds = getRandomDelay();
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        status: 200,
      });
    }, delayInMilliseconds);
  });
}

export const addUrlParam = (urlParamString, paramToAdd) => {
  if (urlParamString === '') {
    return '?' + paramToAdd;
  } else {
    return urlParamString + '&' + paramToAdd;
  }
};

function _filterDocTypes(docs, filteringDocTypes) {
  if (!filteringDocTypes) return docs;
  if (filteringDocTypes.length === 0) return docs;
  return docs.filter((d) => filteringDocTypes.includes(d.doc_type));
}

export const createRows = (docDays, lookup, filteringDocTypes) => {
  const rows = [];
  docDays.forEach((day) => {
    const docsInADay = lookup[day];
    const filteredDocs = _filterDocTypes(docsInADay, filteringDocTypes);
    if (filteredDocs.length > 0) {
      rows.push({
        day,
        docs: filteredDocs,
      });
    }
  });
  return rows;
};

export const unify = (arrays) => {
  let v = [];
  arrays.forEach((a) => (v = [...v, ...a]));
  return v;
};

export const mockApi = async (response, error, timeout = 1000) => {
  await sleep(timeout);
  if (response) {
    return { status: 200, data: response };
  }
  return { status: 400, error: error };
};

export function isJsonCodeBlock(str) {
  return str.startsWith('```json') && str.endsWith('```');
}

export function extractJsonString(str) {
  if (str.startsWith('```json') && str.endsWith('```')) {
    return str.slice(7, -3).trim();
  }
  return null;
}
